import {
  Checkbox,
  FormControl,
  FormLabel,
  ListItemText,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import { MenuItem } from "material-ui";
import { MuiThemeProvider } from "material-ui/styles";
import React, { useState, useEffect, useMemo } from "react";
import { Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
// import { USERCOLUMNS } from "../FilteringTable/Columns";

import { ToastContainer } from "react-toastify";
import {
  AssignCourseToTeam,
  getIsPublishedCourses,
  getTeamById,
  getTeamList,
  getUser,
  getUsers,
} from "../../../services/PostsService";
import { allTeamsList } from "../../../store/actions/teams";
import { TEAMS_MEMBERS, TEAMS_TABLE } from "../table/FilteringTable/Columns";
import { GlobalFilter } from "../table/FilteringTable/GlobalFilter";
function TeamMembers(props) {
  const params = useParams();
  const columns = useMemo(() => TEAMS_MEMBERS, []);
  const [teamList, setTeamList] = useState([]);
  const data = teamList;
  const [courses, setCourses] = useState([]);
  const [coursesmodal, setcoursesmodal] = useState(false);
  const [usermodal, setUserModal] = useState(false);
  const [teamId, setTeamId] = useState("");
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (params.id) {
      const fetchTeams = async () => {
        await getTeamById({ id: params.id }).then((response) => {
          setTeamList(response.data.result.map((item) => item._id));
        });
      };
      const fetchCourses = async () => {
        await getIsPublishedCourses().then((response) => {
          setCourses(response.data.courses);
        });
      };

      const fetchUsers = async () => {
        await getUsers().then((response) => {
          setUsers(response.data.result);
        });
      };
      fetchUsers();
      fetchCourses();
      fetchTeams();
    }
  }, []);

  const [input, setInput] = useState({
    courseselected: [],
    userselected: [],
  });
  const handleChangeCoupon = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const assignthesecourses = async () => {
    try {
      const teamMember = [];
      data.map((team) => teamMember.push(team?.userId));
      // console.log(teamMember)

      const courseIds = input.courseselected.filter(
        (item, index) =>
          item !== filteredTeamMember[0]?.courses.map((d) => d.courseId)[index]
      );
      const res = await AssignCourseToTeam({
        courseId: courseIds,
        teamMember: teamMember,
        teamId: params.id,
      });
      toast.success("Courses Assigned Successfully", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const hideModal = () => {
    setcoursesmodal(false);
    setUserModal(false);
  };
  const handleSubmit = () => {};

  useEffect(() => {
    if (filteredTeamMember[0]?.courses?.length > 0) {
      setInput({
        ...input,
        courseselected: filteredTeamMember[0]?.courses.map(
          (item) => item.courseId
        ),
      });
    }
  }, [data]);

  function addUser() {
    e.preventDefault();
    const modifyoptions = options.map((option) => ({
      name: option.split("-")[0],
      userId: option.split("-")[1],
      teamName: teamName,
      teamId: teamId,
    }));
    console.log(modifyoptions);
    const formData = {
      teamId: teamId,
      teamMember: modifyoptions,
    };
    // const data = await AddUsersInTeam(formData);
    toast.success(`User have been Assigned  ${modifyoptions?.length} in `, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    hideModal();
  }

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;
  const { globalFilter, pageIndex } = state;
  console.log(teamList);
  const filteredTeamMember = teamList?.filter((d) => d._id == teamId);
  console.log(filteredTeamMember[0]);
  const filterteam = filteredTeamMember?.teamMembers?.map((d) => d.name);
  console.log(teamId);
  console.log(teamList);

  return (
    <>
      <ToastContainer />
      <Link to="/users">
        <button className="btn btn-danger shadow">
          <i class="fa-solid fa-arrow-left mx-1"></i> Back
        </button>
      </Link>
      <div className="card-3 mt-4">
        <span style={{ float: "right" }}>
          <button
            onClick={() => {
              setcoursesmodal(true);
            }}
            className="btn btn-primary shadow"
          >
            Assign Courses
          </button>
        </span>
        <span style={{ float: "right", marginRight: "10px" }}>
          <button
            onClick={() => {
              setUserModal(true);
            }}
            className="btn btn-primary shadow"
          >
            Add User
          </button>
        </span>
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        <table {...getTableProps()} className="table  display">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                    {column.canFilter ? column.render("Filter") : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="">
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="cursor-pointer active-hover active-hover-fw-bold"
                  onClick={
                    () => props.history.push(`/team/${row.original._id}`)
                    // setTeamId(row.original._id)
                  }
                >
                  {console.log(row)}
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="d-flex justify-content-between">
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
            {""}
          </span>
          <span className="table-index">
            Go to page :{" "}
            <input
              type="number"
              className="ml-2"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
            />
          </span>
        </div>
        <div className="text-center">
          <div className="filter-pagination  mt-3">
            <button
              className=" previous-button"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </button>

            <button
              className="previous-button"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Previous
            </button>
            <button
              className="next-button"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              Next
            </button>
            <button
              className=" next-button"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>
          </div>
        </div>
        {/* {teamId && <TeamMembers teamId={teamId} teamList={teamList} />} */}
      </div>
      <Modal show={coursesmodal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Courses</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MuiThemeProvider>
            <div className="row">
              <div className="col">
                <FormControl variant="standard" className="coupon-form-input">
                  <FormLabel htmlFor="coupon-input">Select Course</FormLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={input.courseselected}
                    onChange={(e) => handleChangeCoupon(e)}
                    input={<OutlinedInput label="Tag" />}
                    name="courseselected"
                    renderValue={(selected) => selected?.join(", ")}
                  >
                    {/* {courses.map((course) => course.name)} */}
                    {courses?.map((course) => (
                      <MenuItem
                        key={course?.course_id}
                        value={course?.course_id}
                      >
                        <Checkbox
                          checked={
                            input?.courseselected?.indexOf(course?.course_id) >
                            -1
                          }
                          // checked={
                          //   input?.courseselected?.indexOf(course?.course_id) >
                          //   -1
                          // }
                        />
                        <ListItemText primary={course?.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>

            <Modal.Footer>
              <button className="btn btn-warning button" onClick={hideModal}>
                Cancel
              </button>
              <button
                onClick={assignthesecourses}
                className="btn btn-warning button modal-btn"
              >
                Assign Courses
              </button>
            </Modal.Footer>
          </MuiThemeProvider>
        </Modal.Body>
      </Modal>
      {/* assign User modal */}
      <Modal show={usermodal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MuiThemeProvider>
            <div className="row">
              <div className="col">
                <FormControl variant="standard" className="coupon-form-input">
                  <FormLabel htmlFor="coupon-input">Select User</FormLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    className="w-100"
                    value={input?.userselected}
                    onChange={(e) => handleChangeCoupon(e)}
                    input={<OutlinedInput label="Tag" />}
                    name="userselected"
                    renderValue={(selected) => selected?.join(", ")}
                  >
                    {/* {courses.map((course) => course.name)} */}
                    {users?.map((user) => (
                      <MenuItem key={user?._id} value={user?._id}>
                        <Checkbox
                          checked={input?.userselected?.indexOf(user?._id) > -1}
                        />
                        <ListItemText
                          primary={
                            "Name" +
                            ":" +
                            user?.name +
                            "  " +
                            "Email" +
                            ":" +
                            user?.email
                          }
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>

            <Modal.Footer>
              <button className="btn btn-warning button" onClick={hideModal}>
                Cancel
              </button>
              <button
                onClick={addUser}
                className="btn btn-warning button modal-btn"
              >
                Add User
              </button>
            </Modal.Footer>
          </MuiThemeProvider>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TeamMembers;
