import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { FiDelete } from 'react-icons/fi'
import { MdDelete } from 'react-icons/md'
import '../../Common/Components/multiselect.css'
function MultiSelectCollege({ selectedData, disabled, data, handleMultiSelectChange, removeMultiSelectChange, placeholder, selectFor }) {
    const [newSelectionData, setnewSelectionData] = useState()
    useEffect(() => {
        if (!!data) {
            const arr = [];
            for (const SingleItem of selectedData) {
                arr?.push(data?.find(item => item._id === SingleItem))
            }
            setnewSelectionData(arr)
        }
    }, [selectedData])

    return (
        <div>
            <div className="mb-3 border border-dark rounded-2 p-3 " controlId="exampleForm.ControlInput1 ">
                <div className='gap-3 d-flex flex-wrap w-100 mb-3'>
                    {!selectedData?.length > 0 ? <>{placeholder}</> : newSelectionData?.map((item, index) => {
                        return <div className='labelButton'>
                            <h5 className='mb-0'>
                                {selectFor === "College" ? item?.collegename : item?.name}
                            </h5>

                            <button className='rounded-2  p-1' onClick={(e) => removeMultiSelectChange(e, item._id)}><MdDelete fontSize={20} /></button>
                        </div>
                    })}</div>
                <select onChange={handleMultiSelectChange} disabled={disabled} className="form-select form-select-lg border border-dark" aria-label="Default select example" >
                    <option selected disabled>{placeholder}</option>
                    {
                        data?.map((item, index) => {
                            return <option value={item._id} key={index} >  {selectFor === "College" ? item?.collegename : item?.name}</option>
                        })
                    }
                </select>
            </div>
        </div>
    )
}

export default MultiSelectCollege