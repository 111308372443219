import React, { useState } from 'react'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import './commontable.css'
import { CSVLink } from 'react-csv';
import Loader from '../Loader/Loader';
import { TiExportOutline } from "react-icons/ti";
import { CiExport } from "react-icons/ci";



function CommonTable({ fetchingCSVdataLoading, columns, data, fetchUserToExport, handleSearchChange, totalRows, csvData, csvName, setsearchQuery, tableType, handlePerRowsChange, handlePageChange }) {

    const customStyles = {
        rows: {
            style: {
                minHeight: '72px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                backgroundColor: "rgb(82, 25, 134)",
                color: "white"
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };
    const tableData = {
        columns,
        data
    }
    // const csvData = data?.map(row => {
    //     const rowData = {};
    //     columns.forEach((col, index) => {
    //         const cellValue = typeof col.selector === 'function' ? col.selector(row, index) : row[col.selector];
    //         rowData[col.name] = cellValue;
    //     });
    //     return rowData;
    // });



    return (
        <>
            {/* <DataTableExtensions
                {...tableData}
            > */}
            <div className='my-4'>

                <div className='w-100 d-flex justify-content-between mb-3'>
                    <div className="d-flex ">
                        <input type="text" placeholder="Search" className='p-2 rounded-2 border border-dark' onChange={(e) => setsearchQuery(e.target.value)} />
                    </div>

                    {/* <input type="date" className='px-2 ' /> */}
                    {
                        !!csvData &&
                            tableType === "user" ? fetchingCSVdataLoading ? <Loader /> :
                            <button className="p-2 px-3 rounded common-btn" onClick={fetchUserToExport}>
                                <CiExport fontSize={28} />
                            </button> :
                            <CSVLink data={csvData} filename={`${csvName}.csv`} className="p-2 px-3 rounded common-btn">
                                <CiExport fontSize={28} />
                            </CSVLink>

                    }
                </div>

                {
                    tableType === "user" ?
                        <DataTable
                            columns={columns}
                            data={data}
                            pagination
                            customStyles={customStyles}
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}

                        /> :
                        <DataTable
                            columns={columns}
                            data={data}

                            customStyles={customStyles}
                            pagination={true}
                            paginationPerPage={5}
                            highlightOnHover
                            paginationRowsPerPageOptions={[5, 10, 15]}
                        />
                }

            </div>
            {/* </DataTableExtensions> */}
            {/* <CompactTable columns={COLUMNS} data={[]} theme={theme} />; */}
        </>
    )
}

export default CommonTable