import Multiselect from "multiselect-react-dropdown";
import React, { useState, useEffect, useMemo } from "react";
import { Dropdown, Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
// import { USERCOLUMNS } from "../FilteringTable/Columns";

import { toast, ToastContainer } from "react-toastify";
import {
  AddUsersInTeam,
  createTeam,
  getTeamList,
  getUsers,
} from "../../../services/PostsService";
import { allTeamsList } from "../../../store/actions/teams";
import { Show } from "../show";
import { TEAMS_TABLE } from "../table/FilteringTable/Columns";
import { GlobalFilter } from "../table/FilteringTable/GlobalFilter";
function Teams(props) {
  const columns = TEAMS_TABLE;
  const [addusermodal, setAddusermodal] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [options, setOptions] = useState([]);
  const data = teamList;
  const [teamId, setTeamId] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchTeams = async () => {
      await getTeamList().then((response) => {
        setTeamList(response?.data?.result);
        dispatch(allTeamsList(response?.data.result || []));
      });
    };
    fetchTeams();
    const fetch = async () => {
      try {
        const response = await getUsers();
        setUsers(response?.data?.result);
      } catch (e) {
        console.log(e);
      }
    };
    fetch();
  }, []);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;
  const { globalFilter, pageIndex } = state;
  const filteredTeamMember = teamList?.filter((d) => d._id == teamId);
  const [teamName, setTeamName] = useState("");
  const [newTeam, setNewTeam] = useState("");
  const [isShowTeam, setIsShowTeam] = useState(false);
  const [users, setUsers] = useState([]);
  const filterteam = filteredTeamMember?.teamMembers?.map((d) => d.name);
  const teamHandler = (item) => {
    setTeamName(item?.teamName);
    setTeamId(item?._id);
  };
  const hideModal = () => {
    setAddusermodal(false);
    setIsShowTeam(false);
  };
  const handleSubmit2 = async (e) => {
    e.preventDefault();
    const modifyoptions = options.map((option) => ({
      name: option.split("-")[0],
      userId: option.split("-")[1],
      teamName: teamName,
      teamId: teamId,
    }));
    const formData = {
      teamId: teamId,
      teamMember: modifyoptions,
    };
    const data = await AddUsersInTeam(formData);
    toast.success(`User have been Assigned  ${modifyoptions?.length} in `, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    hideModal();
  };

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    const formData = {
      teamName: newTeam,
    };
    console.log(formData);
    const data = await createTeam(formData);
    toast.success("Team Created Successfully");
    await getTeamList().then((response) => {
      setTeamList(response?.data?.result);
      dispatch(allTeamsList(response?.data.result || []));
    });
    setIsShowTeam(false);
    // hideModal();
  };

  return (
    <>
      <ToastContainer />
      <Link to="/users">
        <button className="btn btn-danger shadow">
          <i class="fa-solid fa-arrow-left mx-1"></i> Back
        </button>
      </Link>
      <Modal show={addusermodal} onHide={() => setAddusermodal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Team</Modal.Title>
        </Modal.Header>
        <Show condition={!isShowTeam}>
          <Modal.Body>
            <Form onSubmit={(e) => handleSubmit2(e)}>
              <Form.Group
                className="mb-3 d-flex justify-content-between align-items-center "
                controlId="formBasicEmail"
              >
                <Form.Label>
                  <h4>Add Team</h4>
                </Form.Label>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {teamName || "Select Team"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {teamList?.map((item) => (
                      <Dropdown.Item onClick={() => teamHandler(item)}>
                        {item?.teamName}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
              <Form.Group></Form.Group>
              <Multiselect
                options={users?.map((item) => item?.name + "-" + item?._id)}
                isObject={false}
                onSelect={(e) => setOptions(e)}
                onRemove={(e) => console.log(e)}
              />
              <Form.Group>
                <div onClick={() => setIsShowTeam(true)}>
                  <button className="btn btn-secondary my-2">
                    Create new Team <span>+</span>
                  </button>
                </div>
              </Form.Group>

              <Modal.Footer>
                <button className="btn btn-warning button" onClick={hideModal}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-warning button modal-btn"
                >
                  Add User
                </button>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Show>
        <Show condition={isShowTeam}>
          <Modal.Body>
            <Form onSubmit={handleSubmit1}>
              <Form.Group className="mb-3 d-flex justify-content-between align-items-center ">
                <div>
                  <h4>Write Team Name</h4>
                  <Form.Control
                    className="form-control"
                    onChange={(e) => setNewTeam(e.target.value)}
                    type="text"
                  />
                </div>
              </Form.Group>
              <Form.Group>
                <div>
                  <button
                    type="submit"
                    className="btn btn-warning button modal-btn"
                  >
                    Create new team
                  </button>
                </div>
              </Form.Group>

              <Modal.Footer>
                <button className="btn btn-warning button" onClick={hideModal}>
                  Cancel
                </button>
                <button
                  className="btn btn-secondary button modal-btn"
                  onClick={() => setIsShowTeam(false)}
                >
                  Select team Members
                </button>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Show>
      </Modal>
      <div className="card-3 mt-4">
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-primary shadow"
            onClick={() => setAddusermodal(true)}
          >
            <i class="fa-solid fa-plus mx-1"></i> Create New Team
          </button>
        </div>
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        <table {...getTableProps()} className="table  display">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                    {column.canFilter ? column.render("Filter") : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="">
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="cursor-pointer active-hover active-hover-fw-bold"
                  onClick={
                    () => props.history.push(`/team/${row.original._id}`)
                    // setTeamId(row.original._id)
                  }
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="d-flex justify-content-between">
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
            {""}
          </span>
          <span className="table-index">
            Go to page :{" "}
            <input
              type="number"
              className="ml-2"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
            />
          </span>
        </div>
        {/* {teamId && <TeamMembers teamId={teamId} teamList={teamList} />} */}
      </div>
    </>
  );
}

export default Teams;
