import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import profileUser from '../../images/user.png'
import Domain from './Domain.json'
import Location from './Location.json'
import Countries from './countries+states+cities.json'
import moment from 'moment';

function InterviewStep1({ interviewState, setinterviewState, setsidebarState }) {
    const [States, setStates] = useState([])
    const [City, setCity] = useState([])
    const handleChange = (e) => {
        const { name, value } = e.target
        setinterviewState({ ...interviewState, [name]: value })
    }

    const LocationArray = Location.sort((a, b) => {
        const valueA = a.value.toLowerCase();
        const valueB = b.value.toLowerCase();

        // Compare the labels
        if (valueA < valueB) {
            return -1;
        }
        if (valueA > valueB) {
            return 1;
        }
        return 0;
    })
    const numbers = Array.from({ length: 35 }, (_, index) => index);

    const handleNext = (e) => {
        e.preventDefault()
        setsidebarState(1)
    }
    useEffect(() => {

    }, [interviewState.jobcountry])

    return (
        <div>
            <form action="">

                <div className=''>
                    <Form>
                        <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control type="text" name="company" value={interviewState?.company} onChange={handleChange} className='border border-dark' placeholder="Full Name" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Job Title</Form.Label>
                            <Form.Control type="text" name="jobtitle" value={interviewState?.jobtitle} onChange={handleChange} className='border border-dark' placeholder="Job Title" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1 border border-dark">
                            <Form.Label>Job Description</Form.Label>
                            <ReactQuill theme="snow" value={interviewState?.jobdescription} placeholder='write the description of job' className='' name='jobdescription' onChange={(value) => {
                                setinterviewState({ ...interviewState, jobdescription: value })
                            }} />

                        </Form.Group>

                        <div className='d-flex gap-4 '>
                            <div className='w-100'>
                                <h6 className='mt-4'>Domain</h6>
                                <select className="form-select form-select-lg border border-dark" aria-label="Default select example" name="domain" onChange={handleChange}>
                                    <option selected disabled>{!!interviewState?.domain ? interviewState?.domain : "Select Domain"}</option>
                                    {
                                        Domain?.map((item, index) => {
                                            return <option key={index} >{item?.value}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className='w-100'>
                                <h6 className='mt-4'>Sub Domain</h6>

                                <select onChange={handleChange} className="form-select form-select-lg border border-dark" aria-label="Default select example" disabled={interviewState?.domain === "" ? true : false} name="jobcategory">
                                    <option selected disabled>{!!interviewState?.jobcategory ? interviewState?.jobcategory : "Select Sub Domain"}</option>
                                    {
                                        Domain?.filter((item) => item.value === interviewState?.domain)[0]?.roles?.map((item, index) => {
                                            return <option key={index}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>

                        </div>
                        <div className='d-flex gap-4'>
                            <div className='w-100'>
                                <h6 className='mt-4'>Country</h6>
                                <select className="form-select form-select-lg border border-dark" aria-label="Default select example" name="jobcountry" onChange={handleChange}>
                                    <option selected disabled>{!!interviewState?.jobcountry ? interviewState?.jobcountry : "Select Country"}</option>
                                    {
                                        Countries?.map((item, index) => {
                                            return <option key={index} value={item?.name}>{item?.name}</option>
                                        })
                                    }

                                </select>
                            </div>
                            <div className='w-100'>
                                <h6 className='mt-4'>State</h6>
                                <select className="form-select form-select-lg border border-dark" aria-label="Default select example" name="jobcity" onChange={handleChange}>
                                    <option selected disabled>{!!interviewState?.jobcity ? interviewState?.jobcity : "Select State"}</option>
                                    {
                                        interviewState?.jobcountry && Countries.find((item) => item.name === interviewState?.jobcountry)?.states?.map((item, index) => {
                                            return <option key={index} value={item?.name}>{item?.name}</option>
                                        })
                                    }

                                </select>
                            </div>

                        </div>
                        <h6 className='mt-4'>City</h6>
                        <select className="form-select form-select-lg border border-dark" aria-label="Default select example" name="jobarea" onChange={handleChange}>
                            <option selected disabled>{!!interviewState?.jobarea ? interviewState?.jobarea : "Select City"}</option>
                            {
                                interviewState?.jobcity && Countries.find((item) => item.name === interviewState?.jobcountry)?.states.find(item => item.name === interviewState.jobcity)?.cities?.map((item, index) => {
                                    return <option key={index} value={item?.name}>{item?.name}</option>
                                })
                            }

                        </select>

                        <h6 className='mt-4'>Job Type</h6>
                        <div className='d-flex gap-4 '>

                            <select className="form-select form-select-lg border border-dark" aria-label="Default select example" name='jobtype' onChange={handleChange}>
                                <option selected disabled>{!!interviewState?.jobtype ? interviewState?.jobtype : "Select type"}</option>
                                <option value="Part Time">Part Time</option>
                                <option value="Full Time">Full Time</option>
                            </select>

                            <select className="form-select form-select-lg border border-dark" aria-label="Default select example" name='jobsubtype' onChange={handleChange}>
                                <option selected disabled>{!!interviewState?.jobsubtype ? interviewState?.jobsubtype : "Select Subtype"}</option>
                                <option value="Hybrid">Hybrid</option>
                                <option value="Remote">Remote</option>
                                <option value="Onsite">Onsite</option>
                            </select>

                        </div>
                        <h6 className='mt-4'>Years of Experience</h6>
                        <div className='d-flex gap-4 w-50'>
                            {/* <select name="experience" onChange={handleChange} className="form-select form-select-lg border border-dark" aria-label="Default select example">
                                <option selected disabled>{!!interviewState?.experience ? interviewState?.experience : "Select type"}</option>
                                {numbers?.map(number => (
                                    <option key={number} value={Number(number)}>{number}</option>
                                ))}
                            </select> */}
                            <Form.Control type="number" name="experience" value={interviewState?.experience} onChange={handleChange} className='border border-dark' placeholder="Your experience" min={0} max={50} />

                        </div>
                        <h6 className='mt-4'>Job Expiry Date</h6>
                        <div className='d-flex gap-4 w-50'>
                            <input type="date" name="expirydate" onChange={handleChange} value={moment(interviewState?.expirydate).format("YYYY-MM-DD")} className='p-2 rounded-2 border border-dark' />

                        </div>
                    </Form>
                    {/* <div className='d-flex justify-content-end'>
                        <button className='common-btn p-2 px-4 rounded-2 mt-3' onClick={handleNext}>Next</button>
                    </div> */}
                </div>

            </form>
        </div>
    )
}

export default InterviewStep1
