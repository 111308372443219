import React, { useRef, useState } from "react";
import {
  AddUsersInTeam,
  bulkUploadList,
  bulkUploadListCourse,
  createTeam,
  getIsPublishedCourses,
  getOrders,
  getTeamList,
  getUsers,
} from "../../../../services/PostsService";
import { useCsvUpload } from "../../hooks/useCsvUpload";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { Dropdown, Form, Modal } from "react-bootstrap";
import { MuiThemeProvider } from "material-ui/styles";
import {
  Checkbox,
  ListItemText,
  OutlinedInput,
  Select,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import { MenuItem } from "material-ui";
import { useSelector } from "react-redux";
import { Show } from "../../show";
import Multiselect from "multiselect-react-dropdown";
import { allTeamsList } from "../../../../store/actions/teams";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { AssignCollegeToUser, getAllColleges, getBatchesByCollegeId } from "../../../../services/apis";

export const BulkUser = () => {
  const ref = useRef(null);
  const [rejected, setRejected] = useState([]);
  const [list, setList] = useState();
  const [list2, setList2] = useState();
  const [modalOpened2, setModalOpened2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [assigncourses, setAssignCourses] = useState(false);
  const [courses, setCourses] = useState([]);
  const [assignteam, setAssignTeam] = useState(false);
  const [AssignCollege, setAssignCollege] = useState(false);
  const { handleFileReader, sheetAccepted } = useCsvUpload();
  const [isStudent, setIsStudent] = useState(false);
  const [assignTeamObj, setassignTeamObj] = useState({
    collegeId: "",
    teamId: ""
  })

  const handleChange = async () => {
    const [file] = ref.current.files;
    if (file) {
      handleFileReader(file, (data) => {
        setList(data);
      });
    }
  };
  const bulkUpload = async () => {
    setLoading(true);
    const filteredList = list.filter((item) => item.email);
    try {
      if (filteredList.length > 0) {
        const response = await bulkUploadList({
          users: filteredList.map((i) => {
            return { role: "USER", ...i };
          }),
          password: password,
          isStudent: isStudent,
          courseAssign: assigncourses,
          courses: coursesId,
        });
        setModalOpened2(false);
        setLoading(false);
        console.log(response);
        if (
          response?.data?.existedEmails &&
          response?.data?.existedEmails?.length > 0
        ) {
          setModalOpened2(true);
          let email = [];
          response?.data?.existedEmails?.forEach((item) => {
            email.push(item?.email);
          });
          setList2(email);
        }
        toast.success(
          `${response?.data?.data?.length} Users Added Successfully. Duplicate Removed: ${response?.data?.duplicatesRemoved}, Missing Email In CSV: ${response?.data?.missingEmails}, Missing Name In CSV: ${response?.data?.missingNames}`,
          {
            position: "top-center",
            autoClose: 7000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.error, {
        position: "top-center",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const bulkUpload2 = async () => {
    setLoading(true);
    const filteredList = list.filter((item) => item.email);
    try {
      if (filteredList.length > 0) {
        const response = await bulkUploadListCourse({
          users: filteredList.map((i) => {
            return { role: "USER", ...i };
          }),
          isStudent: isStudent,
          password: password,
          courseAssign: assigncourses,
          courses: coursesId,
        });

        setModalOpened2(false);
        setLoading(false);
        toast.success("Users Added Successfully", {
          position: "top-center",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      setLoading(false);
      const error = err?.response?.data?.error;
      console.log(err.response);
      if (err?.response?.status === 400) {
        setModalOpened2(true);
        setList2(err?.response?.data?.emails || []);
      } else {
        toast.success("Uploaded", {
          position: "top-center",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  const [addusermodal, setAddusermodal] = useState(false);
  const [options, setOptions] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [users, setUsers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [teamName, setTeamName] = useState("");
  const [teamId, setTeamId] = useState("");
  const [isShowTeam, setIsShowTeam] = useState(false);
  const [newTeam, setNewTeam] = useState("");
  const dispatch = useDispatch();
  const handleSubmit1 = async (e) => {
    e.preventDefault();
    const formData = {
      teamName: newTeam,
    };
    console.log(formData);

    const data = await createTeam(formData);
    toast.success("Team Created Successfully");
    setIsShowTeam(false);
    // hideModal();
  };
  const teamHandler = (item) => {
    setTeamName(item?.name);
    setTeamId(item?._id);
  };
  useEffect(() => {
    const fetch3 = async () => {
      try {
        const response = await getUsers();
        setUsers(response?.data?.result);
      } catch (e) {
        console.log(e);
      }
    };
    const fetch2 = async () => {
      const response = await getOrders();
      setOrders(response?.data?.result);
    };
    fetch3();
    fetch2();
  }, []);
  useEffect(() => {
    const fetchTeams = async () => {
      await getTeamList().then((response) => {
        console.log(response);
        setTeamList(response?.data?.result || []);
        dispatch(allTeamsList(response?.data.result || []));
      });
    };
    fetchTeams();
  }, [addusermodal, isShowTeam]);

  const handleSubmit2 = async (e) => {
    if (!teamName) {
      alert("Please select team");
    }
    e.preventDefault();
    const modifyoptions = list.map((item) => ({
      name: item.name,
      email: item.email,
      phoneNumber: item.phoneNumber,
      userId: Math.random().toString(36).substr(2, 9),
      teamId: assignTeamObj.teamId,
    }));
    console.log(modifyoptions);
    const formData = {
      teamId: assignTeamObj.teamId,
      teamMember: modifyoptions,
    };
    const data = await AddUsersInTeam(formData);
    toast.success(`User have been Assigned  ${modifyoptions?.length} in `, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    hideModal();
  };

  const [coursesmodal, setcoursesmodal] = useState(false);
  const [CollegeModal, setCollegeModal] = useState(false);
  const [coursesId, setCoursesId] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const res = await getIsPublishedCourses();
        setCourses(res?.data?.courses);
      } catch (e) {
        console.log(e);
      }
    };
    fetchCourses();
  }, []);

  const [input, setInput] = useState({
    courseselected: [],
    userselected: [],
  });
  const handleChangeCoupon = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };
  const assignthesecourses = () => {
    setCoursesId(input?.courseselected);
    hideModal();
  };
  const hideModal = () => {
    setcoursesmodal(false);
    setAddusermodal(false);
  };



  const [CollegeForm, setCollegeForm] = useState({
    collegeId: ""
  })
  const assignCollegeToUser = async () => {
    try {
      const payload = {
        collegeId: CollegeForm.collegeId,
        users: list?.map((item) => item.email)
      }
      await AssignCollegeToUser(payload)
      setCollegeModal(false)

      toast.success("College Updated ")
    } catch (error) {
      console.log()
    }
  }


  const [collegeData, setcollegeData] = useState([])
  const [teamData, setteamData] = useState([])

  console.log(assignTeamObj)

  // assign team
  const fetchCollegeData = async () => {
    try {
      const res = await getAllColleges()
      // console.log(res)
      setcollegeData(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  const fetchTeamData = async () => {
    try {
      const res = await getBatchesByCollegeId(assignTeamObj.collegeId)
      setteamData(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchCollegeData()
  }, [])

  useEffect(() => {
    if (assignTeamObj?.collegeId) {
      fetchTeamData()
    }
  }, [assignTeamObj?.collegeId])


  const postAssignTeam = async (e) => {
    e.preventDefault()
    try {
      const payload = {
        id: params.id,
        collegeId: assignTeamObj?.collegeId,
        teamId: assignTeamObj?.teamId
      }
      const res = await AssignTeamToUser(payload)
      console.log(res)
    } catch (error) {
      console.log(error)

    }
  }




  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {loading && <div id="cover-spin"></div>}

      <div className="row">
        <Link to="/users">
          <button className="btn btn-danger shadow">
            <i class="fa-solid fa-arrow-left mx-1"></i> Back
          </button>
        </Link>
        <div className="card-3 mt-4">
          <div className="d-flex justify-content-end mb-2">
            <button className="btn btn-primary white-100 ">
              <a
                className="white-100 cursor-pointer"
                href="https://viosa-storage-2.s3.amazonaws.com/userlist.xlsx"
                download
              >
                Sample File
              </a>
            </button>
          </div>
          <div className=" text-center">
            <div className="card-body">
              <div className="row my-2">
                <h2>IMPORT USERS </h2>
              </div>
              <div className="row my-3">
                <h6>Import Users on your Viosa Site</h6>
              </div>
              <div className="row d-flex align-items-center justify-content-center mb-3">
                <input
                  style={{ width: "225px" }}
                  type="file"
                  ref={ref}
                  accepted={sheetAccepted}
                  onChange={handleChange}
                />
              </div>
              <div className="row my-4">
                <p style={{ margin: "0px" }}>
                  We Accept Name, Email and Mobile number, Name and Email are
                  required.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {list && (
        <>
          <table className="table  display">
            <thead>
              <tr>
                <th>SNo</th>
                <th>User Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Role</th>
                <th>Password</th>
              </tr>
            </thead>
            <tbody>
              {list?.map((data, id) => {
                return (
                  <tr
                    key={data}
                    style={{
                      backgroundColor: list2?.includes(data?.email)
                        ? "red"
                        : "",
                    }}
                  >
                    <td>{id + 1}</td>
                    <td>{data?.name}</td>

                    <td>{data?.email}</td>
                    <td>{data?.phoneNumber}</td>
                    <td>{data?.role}</td>
                    <td>{data?.password}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="d-flex align-items-center justify-content-center">
            <input
              type="checkbox"
              className="form-check-input"
              onClick={(e) => setPassword(e.target.checked)}
            />
            <h6 className="mt-2 pt-1 mx-2">Send Reset Password to user</h6>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <input
              type="checkbox"
              className="form-check-input"
              onClick={(e) => setAssignTeam(e.target.checked)}
            />
            <h6 className="mt-2 pt-1 mx-2">Assign Team</h6>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <input
              type="checkbox"
              className="form-check-input"
              onClick={(e) => setAssignCourses(e.target.checked)}
            />
            <h6 className="mt-2 pt-1 mx-2">Assign Courses</h6>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <input
              type="checkbox"
              className="form-check-input"
              onClick={(e) => setAssignCollege(e.target.checked)}
            />
            <h6 className="mt-2 pt-1 mx-2">Assign College</h6>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <input
              type="checkbox"
              className="form-check-input"
              onClick={(e) => setIsStudent(e.target.checked)}
            />
            <h6 className="mt-2 pt-1 mx-2">Potential College Student</h6>
          </div>

          {assignteam ? (
            <>
              <button
                onClick={() => {
                  setAddusermodal(true);
                }}
                className="btn btn-primary shadow me-4"
              >
                Assign Team
              </button>
            </>
          ) : (
            <></>
          )}
          {assigncourses ? (
            <>
              <button
                onClick={() => {
                  setcoursesmodal(true);
                }}
                className="btn btn-primary shadow me-4"
              >
                Assign Courses
              </button>
            </>
          ) : (
            <></>
          )}
          {AssignCollege ? (
            <>
              <button
                onClick={() => {
                  setCollegeModal(true);
                }}
                className="btn btn-primary shadow me-4"
              >
                Assign College
              </button>
            </>
          ) : (
            <></>
          )}

          <button className="btn btn-primary" onClick={() => bulkUpload()}>
            Submit
          </button>
          <div className="d-flex justify-content-end align-items-center">
            <a className="mx-3">
              Do you want to assign courses to already registrered users?
            </a>
            <button className="btn btn-primary" onClick={() => bulkUpload2()}>
              Bulk User Course
            </button>
          </div>
        </>
      )}
      <Modal show={coursesmodal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Courses</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MuiThemeProvider>
            <div className="row">
              <div className="col">
                <FormControl variant="standard" className="coupon-form-input">
                  <FormLabel htmlFor="coupon-input">Select Course</FormLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={input?.courseselected}
                    onChange={(e) => handleChangeCoupon(e)}
                    input={<OutlinedInput label="Tag" />}
                    name="courseselected"
                    renderValue={(selected) => selected?.join(", ")}
                  >
                    {/* {courses.map((course) => course.name)} */}
                    {courses?.map((course) => (
                      <MenuItem
                        key={course?.course_id}
                        value={course?.course_id}
                      >
                        <Checkbox
                          checked={
                            input?.courseselected?.indexOf(course?.course_id) >
                            -1
                          }
                        />
                        <ListItemText primary={course?.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>

            <Modal.Footer>
              <button className="btn btn-warning button" onClick={hideModal}>
                Cancel
              </button>
              <button
                onClick={assignthesecourses}
                className="btn btn-warning button modal-btn"
              >
                Assign Courses
              </button>
            </Modal.Footer>
          </MuiThemeProvider>
        </Modal.Body>
      </Modal>

      <Modal show={addusermodal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Team</Modal.Title>
        </Modal.Header>
        <Show condition={!isShowTeam}>
          <Modal.Body>
            <Form onSubmit={(e) => handleSubmit2(e)}>


              <label htmlFor="">College</label>
              <select className="form-select form-select-lg border border-dark" aria-label="Default select example" onChange={(e) => setassignTeamObj({ ...assignTeamObj, collegeId: e.target.value })} >
                <option selected disabled>{!!assignTeamObj?.collegeId ? collegeData?.find((item) => item._id === assignTeamObj?.collegeId)?.collegename : "Select College"}</option>
                {
                  collegeData?.map((item, index) => {
                    return <option value={item._id}>{item?.collegename}</option>
                  })
                }
              </select>

              <label className="mt-3" htmlFor="">Teams</label>
              <select disabled={teamData?.length > 0 ? false : true} className="form-select form-select-lg border border-dark" aria-label="Default select example" onChange={(e) => setassignTeamObj({ ...assignTeamObj, teamId: e.target.value })} >
                <option selected disabled>{!!assignTeamObj?.teamId ? teamData?.find((item) => item._id === assignTeamObj?.teamId)?.name : "Select Team"}</option>
                {
                  teamData?.map((item, index) => {
                    return <option value={item._id}>{item?.name}</option>
                  })
                }
              </select>
              {/* <Form.Label>
                  <h4>Assign Team</h4>
                </Form.Label> */}
              {/* <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {teamName || "Select Team"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {teamList?.map((item) => (
                      <Dropdown.Item onClick={() => teamHandler(item)}>
                        {item?.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown> */}
              <Form.Group></Form.Group>
              {/* <Multiselect
                options={users?.map((item) => item?.name + "-" + item?._id)}
                isObject={false}
                onSelect={(e) => setOptions(e)}
                onRemove={(e) => console.log(e)}
              /> */}

              {/* <Form.Group>
                <div onClick={() => setIsShowTeam(true)}>
                  <a className="btn btn-outline-secondary  my-2">
                    Create new Team <span>+</span>
                  </a>
                </div>
              </Form.Group> */}

              <Modal.Footer>
                <button className="btn btn-warning button" onClick={hideModal}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-warning button modal-btn"
                >
                  Assing To Team
                </button>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Show>
        <Show condition={isShowTeam}>
          <Modal.Body>
            <Form onSubmit={handleSubmit1}>
              <Form.Group className="mb-3 d-flex justify-content-between align-items-center ">
                <div>
                  <h4>Write Team Name</h4>
                  <Form.Control
                    className="form-control"
                    onChange={(e) => setNewTeam(e.target.value)}
                    type="text"
                  />
                </div>
              </Form.Group>
              <Form.Group>
                <div>
                  <button
                    type="submit"
                    className="btn btn-warning button modal-btn"
                  >
                    Create new team
                  </button>
                </div>
              </Form.Group>

              <Modal.Footer>
                <button className="btn btn-warning button" onClick={hideModal}>
                  Cancel
                </button>
                <button
                  className="btn btn-secondary button modal-btn"
                  onClick={() => setIsShowTeam(false)}
                >
                  Select team Members
                </button>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Show>
      </Modal>
      <Modal show={modalOpened2} onHide={() => setModalOpened2(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Rejected Emails (Already Registered)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table>
            <thead>
              <tr>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {list2?.map((item) => (
                <>
                  <tr>{item}</tr>
                </>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>

      <Modal show={CollegeModal} onHide={() => setCollegeModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Assign College</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="">College</label>
          <select className="form-select form-select-lg border border-dark" aria-label="Default select example" onChange={(e) => setCollegeForm({ ...CollegeForm, collegeId: e.target.value })} >
            <option selected disabled>{!!CollegeForm?.collegeId ? collegeData?.find((item) => item._id === CollegeForm?.collegeId)?.collegename : "Select College"}</option>
            {
              collegeData?.map((item, index) => {
                return <option value={item._id}>{item?.collegename}</option>
              })
            }
          </select>
          <Modal.Footer>
            <button
              className="btn btn-warning button"
              onClick={() => setCollegeModal(false)}
            >
              Cancel
            </button>
            <button
              // type="submit"
              onClick={assignCollegeToUser}
              className="btn btn-warning button modal-btn"
            >
              Update
            </button>
          </Modal.Footer>
          {/* <table>
            <thead>
              <tr>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {list2?.map((item) => (
                <>
                  <tr>{item}</tr>
                </>
              ))}
            </tbody>
          </table> */}
        </Modal.Body>
      </Modal>
      {/* <Modal show={modalOpened} onHide={() => setModalOpened(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Rejected User List (Reason Already Registered)
          </Modal.Title>
        </Modal.Header>
        <Show condition={!isShowTeam}>
          <Modal.Body>
            <table>
              <tr>
                <th>Sl No</th>
                <th>Email</th>
                <th>Mobile</th>
              </tr>
              {rejected?.map((item, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item?.rejectedEmail}</td>
                  <td>{item?.rejectedPhone}</td>
                </tr>
              ))}
            </table>
          </Modal.Body>
        </Show>
        <Show condition={isShowTeam}>
          <Modal.Body>
            <Form onSubmit={handleSubmit1}>
              <Form.Group className="mb-3 d-flex justify-content-between align-items-center ">
                <div>
                  <h4>Write Team Name</h4>
                  <Form.Control
                    className="form-control"
                    onChange={(e) => setNewTeam(e.target.value)}
                    type="text"
                  />
                </div>
              </Form.Group>
              <Form.Group>
                <div>
                  <button
                    type="submit"
                    className="btn btn-warning button modal-btn"
                  >
                    Create new team
                  </button>
                </div>
              </Form.Group>

              <Modal.Footer>
                <button className="btn btn-warning button" onClick={hideModal}>
                  Cancel
                </button>
                <button
                  className="btn btn-secondary button modal-btn"
                  onClick={() => setIsShowTeam(false)}
                >
                  Select team Members
                </button>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Show>
      </Modal> */}
    </>
  );
};
