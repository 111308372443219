import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { PostchangeCollegePassword } from '../services/apis'

function CollegeProfile() {
    const userName = localStorage.getItem("userName")
    const userId = localStorage.getItem("userId")
    const [changePassword, setchangePassword] = useState({
        oldpassword: "",
        newpassword: ""
    })
    const handleChange = (e) => {
        const { name, value } = e.target
        setchangePassword({ ...changePassword, [name]: value })
    }
    // console.log(changePassword)
    const postChangePassword = async () => {
        try {
            const payload = {
                ...changePassword,
                collegeId: userId
            }
            await PostchangeCollegePassword(payload)
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div>
            <div className='w-50'>

                <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
                    <Form.Label>College Name</Form.Label>
                    <Form.Control type="text" name="College" disabled value={userName} className='border border-dark' placeholder="Full Name" />
                </Form.Group>
                <div className='d-flex justify-justify-content-end w-100'>
                    <button className='common-btn p-2 rounded-2'>Update</button>
                </div>
            </div>

            <hr className='my-4' />
            <h3 className='mb-3'>Change Password</h3>
            <div className='w-50'>
                <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
                    <Form.Label>Old Password</Form.Label>
                    <Form.Control onChange={handleChange} type="password" name="oldpassword" className='border border-dark' placeholder="Old Password" />
                </Form.Group>
                <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control onChange={handleChange} type="password" name="newpassword" className='border border-dark' placeholder="New Password" />
                </Form.Group>
                <div className='d-flex justify-justify-content-end'>
                    <button className='common-btn p-2 rounded-2' onClick={postChangePassword}>Change</button>
                </div>
            </div>

        </div>
    )
}

export default CollegeProfile