const sortObjectKeys = (obj) =>
  Object.entries(obj)
    .sort()
    .reduce((o, [k, v]) => ((o[k] = v), o), {});

export const isEqual = (val1, val2) => {
  if (typeof val1 === "number" && typeof val2 === "number")
    return val1 === val2;
  if (typeof val1 === "string" && typeof val2 === "string")
    return val1 === val2;
  if (Array.isArray(val1) && Array.isArray(val2)) {
    return JSON.stringify(val1) === JSON.stringify(val2);
  }
  if (typeof val1 === "object" && typeof val2 === "object") {
    return (
      JSON.stringify(sortObjectKeys(val1)) ===
      JSON.stringify(sortObjectKeys(val2))
    );
  }
  return false;
};

export const ShowActive = ({
  id,
  title,
  icon,
  active,
  setSelected,
  image,
  className,
}) => {
  return (
    <li
      style={{ width: "200px" }}
      className={active ? className + " " + "active" : className}
      onClick={() => setSelected(id)}
    >
      <div className="d-flex justify-content-between">
        <div>
          <p>{title}</p>
        </div>
      </div>
      <i class={`${icon} mx-2`} />
      <img src={image} height={12} />
    </li>
  );
};

export const Dateformat = (date) => {
  const formatDate = new Date(date).toDateString();
  return formatDate;
};
