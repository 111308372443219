import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import profile from "../../images/viosa.png";
import { ThemeContext } from "../../context/ThemeContext";
import { CiUser } from "react-icons/ci";
import { AiOutlineUserAdd } from "react-icons/ai";
import { SiGoogledocs } from "react-icons/si";
import { GoPencil } from "react-icons/go";
import { MdDelete, MdModeEdit, MdOutlineSchedule } from "react-icons/md";
import { FiUserCheck } from "react-icons/fi";
import { DeleteInterview, DeleteInterviewById, getAllInterview, getStudentInterview, getStudentReport, searchInterviewByText } from "../../services/apis";
import Loader from "../../Common/Loader/Loader";
import CommonTable from "../../Common/Table/CommonTable";
import DeleteModal from "../../Common/Components/DeleteModal";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from 'moment';
import Reports from '../../Common/Reports/Reports'

function ViewReports() {
    const { changeBackground } = useContext(ThemeContext);
    const [ReportShow, setReportShow] = useState(null)
    const [reportsData, setreportsData] = useState([])
    const [loading, setloading] = useState(false)
    const navigate = useHistory()
    const params = useParams()
    const { interviewId, id } = params

    const fetchReports = async () => {
        try {
            let interviewList = [];
            setloading(true)
            const payload = {
                interviewId: interviewId,
                userId: id
            }
            const res = await getStudentReport(payload)
            setreportsData(res.data.data)

            // setInterviewsData(res.data.interviews)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log(error)
        }
    }

    useEffect(() => {
        fetchReports()
    }, [])

    // const handleCollegeDelete = async () => {
    //     try {
    //         if (value === true) {
    //             const newArr = InterviewsData?.filter((item, index) => item._id !== DeletionObject?._id)
    //             setInterviewsData(newArr)
    //             await deleteBatchById(DeletionObject?._id)
    //         }
    //         console.log(value)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }


    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Reports',
            selector: (row, index) => `Report ${index + 1}`,
            sortable: true,
            grow: 2
        },
        {
            name: 'Time',
            selector: row => moment(row.updatedAt).format('DD-MM-YYYY (HH:MM)'),
            sortable: true
        },

        {
            name: 'Action',
            selector: row => <>
                {
                    <div className="p-2 rounded-2" onClick={() => setReportShow(row)}>
                        <button className="common-btn p-3 rounded-2 ">View Reports</button>,
                    </div>
                }
            </>,
            sortable: true
        }
    ]


    // deletion 


    // <----------------------------------------delete batch-------------------------------------------->

    // const [showDelete, setshowDelete] = useState(false)
    // const [DeletionObject, setDeletionObject] = useState()

    // const ShowDeleteModal = (value) => {
    //     setDeletionObject(value)
    //     setshowDelete(true)
    // }
    // const HideDeleteModal = () => {
    //     setshowDelete(false)
    // }

    // const handleDeleteDecision = async (value) => {
    //     try {
    //         if (value === true) {
    //             const newArr = InterviewsData?.filter((item, index) => item._id !== DeletionObject?._id)
    //             setInterviewsData(newArr)
    //             await DeleteInterviewById(DeletionObject?._id)
    //         }
    //         console.log(value)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // const handleSearchChange = async (e) => {
    //     if (e.target.value.length > 2) {
    //         try {
    //             const res = await searchInterviewByText(e.target.value)
    //             setInterviewsData(res.data.interviews)
    //         } catch (error) {
    //             console.log(error)
    //         }
    //     } else if (e.target.value.length === 0) {
    //         fetchInterviews()
    //     }
    // }
    // useEffect(() => {

    // }, [searchQuery])
    const csvData = reportsData?.map((row, index) => ({
        "S No.": index + 1,
        "Name": row?.name,
    }));


    return (
        <>
            <section>
                {/* <div className="d-flex w-100 justify-content-between align-items-center">
                    <div className="d-flex ">
                        <input type="text" placeholder="Search Interview" className='p-2 rounded-2 border border-dark' onChange={handleSearchChange} />
                    </div>
                    <button className="p-3 rounded mb-4 text-white px-4 " style={{ background: "#521986 " }} onClick={() => navigate.push('/interview-pro/interview/add')}>Create Interview</button>
                </div> */}
                wifoudshyg

                {
                    !ReportShow ?
                        loading ? <Loader /> : <CommonTable
                            csvData={csvData} csvName={"Tool-Users-Data"}
                            data={reportsData}
                            columns={columns} />
                        : <Reports ReportShowData={ReportShow} />
                }
                {/* <DeleteModal DeletionObject={DeletionObject} showDelete={showDelete} ShowDeleteModal={ShowDeleteModal} handleDeleteDecision={handleDeleteDecision} HideDeleteModal={HideDeleteModal} field={DeletionObject?.jobtitle} />
 */}

            </section>
        </>
    )
}

export default ViewReports