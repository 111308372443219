import { Button } from "bootstrap";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  addEmbeddedAction,
  addEmbeddedLink,
  addURLAction,
} from "../../../../../store/actions/section";

export const Embedded = ({ chapterID, sectionID }) => {
  const dispatch = useDispatch();
  const [embedded, setEmbeded] = useState("");
  const embededHandler = (e) => {
    dispatch(addEmbeddedAction(e.target.value));
    setEmbeded(e.target.value);
  };
  const addEmbedded = () => {
    dispatch(addEmbeddedLink());
  };

  return (
    <div>
      <input
        type="text"
        className="form-control w-50 mx-auto text-center embedcode"
        maxlength="1500"
        value={embedded}
        onChange={embededHandler}
        placeholder="Paste embed code <iframe..>....</iframe>"
      />
      <div className="mt-3 d-flex justify-content-center">
        <button
          variant="success"
          className="btn btn-primary"
          onClick={() => addEmbedded()}
        >
          <i class="fa-solid fa-plus"></i>
          Add Embedded Link
        </button>
      </div>
      <br />
    </div>
  );
};
export const URL = ({ chapterID, sectionID, name }) => {
  const cId = window.location.pathname.split("/")[2];
  const dispatch = useDispatch();
  const [url, setURL] = useState("");
  const urlHandler = (e) => {
    dispatch(
      addURLAction({
        courseId: cId,
        sectionID: sectionID,
        chapterID: chapterID,
        url: e.target.value,
      })
    );
    setURL(e.target.value);
  };
  const addEmbedded = () => {
    dispatch(addEmbeddedLink());
  };

  return (
    <div>
      <input
        type="text"
        className="form-control w-50 mx-auto text-center embedcode"
        maxlength="1500"
        defaultValue={name}
        onChange={(e) => urlHandler(e)}
        placeholder="Paste URL"
      />
      <div className="mt-3 d-flex justify-content-center">
        <button
          variant="success"
          className="btn btn-primary"
          onClick={() => addEmbedded()}
        >
          <i class="fa-solid fa-plus"></i>
          Add URL
        </button>
      </div>
      <br />
    </div>
  );
};
