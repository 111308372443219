import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import profile from "../../images/viosa.png";
import { ThemeContext } from "../../context/ThemeContext";
import { CiUser } from "react-icons/ci";
import { AiOutlineUserAdd } from "react-icons/ai";
import { SiGoogledocs } from "react-icons/si";
import { GoPencil } from "react-icons/go";
import { MdDelete, MdModeEdit, MdOutlineSchedule } from "react-icons/md";
import { FiUserCheck } from "react-icons/fi";
import { DeleteInterview, DeleteInterviewById, getAllInterview, getStudentInterview, searchInterviewByText } from "../../services/apis";
import Loader from "../../Common/Loader/Loader";
import CommonTable from "../../Common/Table/CommonTable";
import DeleteModal from "../../Common/Components/DeleteModal";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

function UserInterviews() {
    const { changeBackground } = useContext(ThemeContext);
    const [InterviewsData, setInterviewsData] = useState([])
    const [loading, setloading] = useState(false)
    const navigate = useHistory()
    const params = useParams()
    const { id } = params
    console.log(InterviewsData)

    const fetchInterviews = async () => {
        try {
            let interviewList = [];
            setloading(true)
            const res = await getStudentInterview(id)
            // console.log(res.data.data)
            const map = new Map()
            for (const team of res.data.data.teams) {
                for (const interview of team.interview) {
                    map.set(interview._id, interview)
                }
            }

            const interviewValues = Array.from(map.values())
            setInterviewsData(interviewValues)
            // setInterviewsData(res.data.interviews)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log(error)
        }
    }

    useEffect(() => {
        fetchInterviews()
    }, [])

    const handleCollegeDelete = async () => {
        try {
            if (value === true) {
                const newArr = InterviewsData?.filter((item, index) => item._id !== DeletionObject?._id)
                setInterviewsData(newArr)
                await deleteBatchById(DeletionObject?._id)
            }
            console.log(value)
        } catch (error) {
            console.log(error)
        }
    }


    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Job category',
            selector: row => row.jobtitle,
            sortable: true,
            grow: 2
        },
        {
            name: 'Job Role',
            selector: row => row.jobcategory,
            sortable: true
        },
        {
            name: 'Industry',
            selector: row => row.domain,
            sortable: true
        },
        {
            name: 'Reports',
            selector: row => <>
                {
                    // row.interviewResult.length > 0 ?
                    <div onClick={() => navigate.push(`/college/interview-pro/users/${id}/${row._id}/reports`)} className='common-btn p-3 rounded-2 cursor-pointer'>
                        View Reports
                    </div>
                }
            </>,
            sortable: true
        }
    ]


    // deletion 


    // <----------------------------------------delete batch-------------------------------------------->

    const [showDelete, setshowDelete] = useState(false)
    const [DeletionObject, setDeletionObject] = useState()

    const ShowDeleteModal = (value) => {
        setDeletionObject(value)
        setshowDelete(true)
    }
    const HideDeleteModal = () => {
        setshowDelete(false)
    }

    const handleDeleteDecision = async (value) => {
        try {
            if (value === true) {
                const newArr = InterviewsData?.filter((item, index) => item._id !== DeletionObject?._id)
                setInterviewsData(newArr)
                await DeleteInterviewById(DeletionObject?._id)
            }
            console.log(value)
        } catch (error) {
            console.log(error)
        }
    }




    const csvData = InterviewsData?.map((row, index) => ({
        "S No.": index + 1,
        "Name": row.name,
        "Email Id": row.email,
        "Registered Date": moment(row.createdAt).format("DD-MM-YYYY"),
        "Phone": row.phoneNumber,
    }));


    const [searchQuery, setsearchQuery] = useState('')

    const [FilteredData, setFilteredData] = useState([])
    useEffect(() => {
        handleSearchChange()
    }, [searchQuery, InterviewsData]);

    const handleSearchChange = async (e) => {
        if (searchQuery === '') {
            setFilteredData(InterviewsData);
        } else {
            const lowercasedQuery = searchQuery.toLowerCase();
            const filteredResults = InterviewsData?.filter(item =>
                Object.values(item).some(
                    val => val && val.toString().toLowerCase().includes(lowercasedQuery)
                )
            );
            setFilteredData(filteredResults);
        }
    }


    return (
        <>
            <section>
                <div className="d-flex w-100 justify-content-between align-items-center">
                    <div className="d-flex ">
                        <input type="text" placeholder="Search Interview" className='p-2 rounded-2 border border-dark' onChange={handleSearchChange} />
                        {/* <button className="common-btn px-3 p-1 ms-4 rounded ">Find</button> */}
                    </div>
                    <button className="p-3 rounded mb-4 text-white px-4 " style={{ background: "#521986 " }} onClick={() => navigate.push('/interview-pro/interview/add')}>Create Interview</button>
                </div>

                {
                    loading ? <Loader /> : <CommonTable data={FilteredData}
                        csvData={csvData} csvName={"Interview-Users"}
                        setsearchQuery={setsearchQuery}
                        columns={columns} />

                }
                <DeleteModal DeletionObject={DeletionObject} showDelete={showDelete} ShowDeleteModal={ShowDeleteModal} handleDeleteDecision={handleDeleteDecision} HideDeleteModal={HideDeleteModal} field={DeletionObject?.jobtitle} />


            </section>
        </>
    )
}

export default UserInterviews