import React, { useState, useEffect, useMemo, useCallback } from "react";
// import PageTitle from "../../../layouts/PageTitle";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import debounce from 'lodash/debounce';

import { Button } from "react-bootstrap";
import { GlobalFilter } from "../FilteringTable/GlobalFilter";
import exportFromJSON from "export-from-json";
import { MdDelete, MdModeEdit, MdOutlineSchedule } from "react-icons/md";
import { saveAs } from 'file-saver';
import { unparse } from 'papaparse';
import {
  AddUser,
  assingCourse,
  deleteUserApi,
  getIsPublishedCourses,
  getUsers,
  getUserStats,
  getUserStatsMonth,
} from "../../../../services/PostsService";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Multiselect from "multiselect-react-dropdown";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { Graph } from "../../Graphjs/Graph";
import DeleteModal from "../../../../Common/Components/DeleteModal";
import { exportUsers, updateUserStatus } from "../../../../services/apis";
import CommonTable from "../../../../Common/Table/CommonTable";
import moment from "moment";

export const UserTable = (props) => {
  const [user, setUser] = useState([]);
  const [csvUsers, setcsvUsers] = useState([])
  const [fetchingCSVdataLoading, setfetchingCSVdataLoading] = useState(false)
  // const columns = useMemo(() => USERCOLUMNS, []);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const data = user;
  const [userDataDay, setUserDataDay] = useState([]);
  const [userDataMonth, setUserDataMonth] = useState([]);
  const month = [
    "JAN",
    "FEB",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUG",
    "SEPT",
    "OCT",
    "NOV",
    "DEC",
  ];
  const [userGraphSelected, setUserGraphSelected] = useState(30);
  const USERCOLUMNS = [
    {
      name: 'S No.',
      selector: (row, index) => index + 1,
      sortable: true,
      cellExport: (row, index) => "",
    },
    {
      name: 'First Name',
      selector: (row, index) => row.name,
      sortable: true,
      cellExport: (row) => row.name,
    },
    {
      name: 'Email Id',
      selector: (row, index) => row.email,
      grow: 2,
      sortable: true,
      cellExport: (row) => row.email,
    },
    {
      name: 'Registered Date',
      selector: (row, index) => moment(row.createdAt).format("DD-MM-YYYY"),
      sortable: true,
      cellExport: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
    },
    {
      name: 'Phone',
      selector: (row, index) => row.phoneNumber,
      sortable: true,
      cellExport: (row) => row.phoneNumber,

    },
    {
      name: 'Status',
      selector: (row, index) => row.active ? <button
        onClick={() => {
          setdeleteUserData(row.original)
          handleshowDelete()
        }}
        style={{
          border: "none",
          padding: "10px",
          borderRadius: "5px",
          backgroundColor: "green",
          color: "white",
        }}
      >
        Activated
      </button> :
        <button
          onClick={() => {
            setdeleteUserData(row.original)
            handleshowDelete()
          }}
          style={{
            border: "none",
            padding: "10px",
            borderRadius: "5px",
            backgroundColor: "tomato",
            color: "white",
          }}
        >
          Deactivated
        </button>
      ,
      sortable: true,
    },
    {
      name: 'Action',
      selector: (row, index) => <Link to={`/user/${row._id}`} className="p-2 border rounded-2 border-dark cursor-pointer"><MdModeEdit fontSize={24} style={{ color: "blue" }} /> </Link>,
      sortable: true,
    }
  ];

  useEffect(() => {
    const fetch = async () => {
      const response = await getUserStats();
      let i = 0;
      const userDayData = response.data.usersArray.map((d) => {
        i++;
        return {
          name: i,
          uv: d,
          pv: 2400,
          amt: 2400,
        };
      });
      setUserDataDay(userDayData);
      const date = new Date();
      let index = -1;
      const userMonthRes = await getUserStatsMonth();

      const userMonthData = userMonthRes.data.usersArray.map((d) => {
        i++;
        if (date.getMonth() - index === -1) index -= 12;
        return {
          name: month[date.getMonth() - i],
          uv: d,
          pv: 2400,
          amt: 2400,
        };
      });
      console.log(userMonthData);
      setUserDataMonth(userMonthData);
    };
    fetch();
    const getCourse = async () => {
      const response = await getIsPublishedCourses();
      setCourses(response?.data?.courses);
    };
    getCourse();
  }, []);

  const [addusermodal, setAddusermodal] = useState(false);
  const [newuser, setnewUser] = useState({
    name: "",
    email: "",
    number: "",
    password: "",
    role: "",
  });
  const [options, setOptions] = useState([]);

  const onChange = (e) => {
    setnewUser({ ...newuser, [e.target.name]: e.target.value });
  };

  const hideModal = (props) => {
    setAddusermodal(false);
  };

  const [userCreate, setUserCreated] = useState(false);
  const [id, setId] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (newuser?.password?.length < 6) {
      toast.error("Password should be at least 6 characters long", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    const formData = {
      name: newuser.name,
      email: newuser.email,
      password: newuser.password,
      phoneNumber: newuser.number,
      confirm_password: newuser.password,
    };
    try {
      const data = await AddUser(formData);
      setId(data.data.data.result._id);
      setUserCreated(true);
      hideModal();
      setLoading(false);
      const response = await getUsers();
      setUser(response.data.result);
      toast.success("User Created Successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (e) {
      setLoading(false);
      toast.error(e.response.data, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    if (userCreate) {
      setLoading(true);
      const fetch = async () => {
        const modifyoptions = options.map((option) => option.course_id);
        const body = {
          userId: id,
          courseIdBody: modifyoptions,
        };
        try {
          if (modifyoptions.length > 0) {
            console.log(body);
            const response = await assingCourse(body);
            setUserCreated(false);
            setLoading(false);
            toast.success(
              `User have been Assigned ${modifyoptions?.length} Courses`,
              {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
        } catch (e) {
          setLoading(false);
          toast.error(e, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      };
      fetch();
    }
  }, [userCreate]);

  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchQuery, setsearchQuery] = useState('')

  // const [loading, setloading] = useState(false)
  const fetch = async (searchQuery = '', currentPage = 1, perPage = 10) => {
    try {
      setLoading(true)
      const query = {
        params: { perPage, currentPage, search: searchQuery },
      }
      const response = await getUsers(query);
      setLoading(false)
      setFilteredData(response.data.result.users)
      setUser(response.data.result.users);
      setTotalRows(response.data.result.totalDocuments)
    } catch (e) {
      setLoading(false)
      console.log(e);
    }
  };
  // useEffect(() => {
  //   fetch();
  //   return <></>;
  // }, [currentPage, perPage]);
  const debouncedLoadData = useCallback(debounce(fetch, 300), []);

  useEffect(() => {
    debouncedLoadData(searchQuery, currentPage, perPage);
  }, [searchQuery, currentPage, perPage, debouncedLoadData]);


  const downloadUsers = () => {
    const fileName = "users";
    const exportType = exportFromJSON.types.csv;
    const data = user;
    exportFromJSON({ data, fileName, exportType });
  };

  const deleteUser = async (id) => {
    let person = prompt(`Type "Delete" to delete this user`);
    if (person === "Delete") {
      try {
        const res = await deleteUserApi({ users: [id] });
        alert(res?.data?.message);
        fetch();
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("You entered wrong text");
    }
  };

  const activateOrDeactivateUser = async (row) => {
    try {
      const payload = {
        status: !deleteUserData?.active,
        id: deleteUserData?._id
      }
      await updateUserStatus(payload)
      fetch()
      toast.success("User Activated")
    } catch (error) {
      console.log(error)
    }
  }


  //fetch user to export
  const fetchUserToExport = async () => {
    try {
      setfetchingCSVdataLoading(true)
      const query = {
        params: { search: searchQuery },
      }
      const response = await exportUsers(query)
      setcsvUsers(response.data.result.users)

      const formattedData = response?.data?.result?.users?.map(item => ({
        "Name": item.name,
        "Email Id": item.email,
        "Registered Date": moment(item.createdAt).format("DD-MM-YYYY"),
        "Phone": item.phoneNumber,
      }));

      const csv = unparse(formattedData);

      setfetchingCSVdataLoading(false)
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, `UserData.csv`);
    } catch (error) {
      setfetchingCSVdataLoading(false)
      console.log(error)
    }
  }

  // pagination
  const [showDelete, setshowDelete] = useState(false)
  const [deleteUserData, setdeleteUserData] = useState(false)
  const handleshowDelete = () => {
    setshowDelete(true)
  }
  const HideDeleteModal = () => {
    setshowDelete(false)
  }
  const handleDeleteDecision = (value) => {
    if (value) {
      activateOrDeactivateUser(deleteUserData)
    } else {
      toast.error("No user Deactivated")
    }
  }



  const csvData = csvUsers?.map((row, index) => ({
    "S No.": index + 1,
    "Name": row.name,
    "Email Id": row.email,
    "Registered Date": moment(row.createdAt).format("DD-MM-YYYY"),
    "Phone": row.phoneNumber,
  }));



  const [FilteredData, setFilteredData] = useState([])
  useEffect(() => {
    // handleSearchChange()
  }, [searchQuery, user]);

  const handleSearchChange = async (e) => {
    if (searchQuery === '') {
      setFilteredData(user);
    } else {
      const lowercasedQuery = searchQuery.toLowerCase();
      const filteredResults = user.filter(item =>
        Object.values(item).some(
          val => val && val.toString().toLowerCase().includes(lowercasedQuery)
        )
      );
      setFilteredData(filteredResults);
    }
  }



  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };


  return (
    <>
      <div className="card-3">
        <div className="card-header">
          <h4 className="card-title">User Management</h4>
        </div>
        <div>
          <Graph
            data={userGraphSelected === 30 ? userDataDay : userDataMonth}
            title="User Statistics"
            setSelect={setUserGraphSelected}
          />
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <span style={{ float: "right" }}>
              <Button
                className="mt-4"
                variant="primary"
                onClick={() => {
                  setAddusermodal(true);
                }}
              >
                <i class="fa-solid fa-user mx-2"></i> Add New User
              </Button>
              <Button className="mt-4 mx-3" variant="primary">
                <i class="fa-solid fa-users mx-2"></i>
                <Link className="white-100" to="/users/bulk">
                  Add Bulk Users
                </Link>
              </Button>
              <Button className="mt-4 mx-3" variant="primary">
                <i class="fa-solid fa-users mx-2"></i>
                <Link className="white-100" to="/users/teams">
                  Teams
                </Link>
              </Button>
              {/* <Button
                className="mt-4 mx-3"
                variant="primary"
                onClick={() => downloadUsers()}
              >
                <i class="fa-solid fa-users mx-2"></i>
                Export Users
              </Button> */}
            </span>
          </div>
          <div className="mt-4 ">
            <CommonTable csvData={csvData} fetchingCSVdataLoading={fetchingCSVdataLoading} fetchUserToExport={fetchUserToExport} tableType="user" totalRows={totalRows} handlePageChange={handlePageChange} handlePerRowsChange={handlePerRowsChange} setsearchQuery={setsearchQuery} columns={USERCOLUMNS} data={FilteredData} />
          </div>
        </div>
      </div >

      {/* Modal Add User */}
      <Modal Modal show={addusermodal} onHide={hideModal} >
        <Modal.Header closeButton>
          <Modal.Title>Add New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Name</Form.Label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                aria-describedby="emailHelp"
                onChange={(e) => onChange(e)}
                value={newuser.name}
                required
                placeholder="Enter name"
                autoComplete="Name"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                aria-describedby="emailHelp"
                onChange={(e) => onChange(e)}
                value={newuser.email}
                required
                placeholder="Enter email"
                autoComplete="email"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Phone</Form.Label>
              <input
                type="number"
                className="form-control"
                id="number"
                name="number"
                aria-describedby="emailHelp"
                onChange={(e) => onChange(e)}
                value={newuser.number}
                required
                placeholder="Enter number"
                autoComplete="number"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <input
                type="text"
                className="form-control"
                id="text"
                name="password"
                title="password must be atleast 6 characters"
                required
                onChange={(e) => onChange(e)}
                value={newuser.password}
                minLength={6}
                placeholder="Enter Password"
              />
            </Form.Group>

            <Multiselect
              options={courses}
              isObject={true}
              onChange={(options) => {
                console(options);
              }}
              displayValue="name"
              onSelect={(e, a) => setOptions(e)}
              showCheckbox={true}
              placeholder="Select Courses"
              onRemove={(e) => console.log(e)}
            />

            <Modal.Footer>
              <button className="btn btn-warning button" onClick={hideModal}>
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-warning button modal-btn"
              >
                {loading ? (
                  <div
                    className="spinner-border text-white ml-4"
                    role="status"
                  ></div>
                ) : (
                  "Add User"
                )}
              </button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal >

      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* user details page */}
      <DeleteModal handleDeleteDecision={handleDeleteDecision} text={`Do you want to deactivate this user ?`} showDelete={showDelete} HideDeleteModal={HideDeleteModal} />

    </>
  );
};
export default UserTable;
