import React, { useEffect, useState } from 'react'
import CommonTable from '../Common/Table/CommonTable'
import Loader from '../Common/Loader/Loader'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { PostCollege, deleteCollege, getAllColleges, updateCollege, updateCollegeStatus } from '../services/apis'
import { MdDelete, MdModeEdit } from 'react-icons/md'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form, Spinner } from 'react-bootstrap'
import DeleteModal from '../Common/Components/DeleteModal'
import { toast } from 'react-toastify'
import { FaRegCopy } from "react-icons/fa";
import { CopyToClipboard } from 'react-copy-to-clipboard';

function College() {
    const [loadingColleges, setloadingColleges] = useState([])
    const [collegeData, setcollegeData] = useState([])
    const [DeleteData, setDeleteData] = useState()
    const [ActivateData, setActivateData] = useState()
    const [isEdit, setisEdit] = useState(false)
    const [collegeForm, setcollegeForm] = useState({
        collegename: "",
        phoneNumber: "",
        email: "",
        password: ""
    })


    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.collegename,
            sortable: true,
            grow: 2
        },
        {
            name: 'Phone Number',
            selector: row => row.phoneNumber,
            sortable: true
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true
        },
        {
            name: 'Copy Url',
            selector: row => <div className='d-flex justify-content-center w-100 ' style={{ cursor: "pointer" }}>
                <CopyToClipboard text={`https://viosa.in/form?collegeid=${row._id}`}
                    onCopy={() => {
                        toast.success("Url Copied")
                    }}
                >

                    <FaRegCopy fontSize={20} />
                </CopyToClipboard>
            </div>,
            sortable: true
        },
        {
            name: 'Status',
            selector: row => <div>{row?.status ? <button className='btn-success w-100 p-2 px-3' onClick={() => {
                deactivateCollege(row._id)
                setActivateData(row)
                handleshowActivate()
            }}>Active</button> : <button className='btn-danger w-100 p-2 px-3' onClick={() => {
                setActivateData(row)
                activateCollege(row._id)
                handleshowActivate()
            }}>Deactive</button>}</div>,
            sortable: true
        },
        {
            name: 'Action',
            selector: row => <div className="d-flex gap-3">
                <button onClick={() => {
                    setcollegeForm(row)
                    setisEdit(true)
                    handleShow()
                }} className="p-2 border rounded-2 border-dark cursor-pointer"><MdModeEdit fontSize={24} style={{ color: "blue" }} /> </button>

                <span className="p-2 border rounded-2 border-dark cursor-pointer" onClick={() => {
                }} ><MdDelete onClick={() => {
                    handleshowDelete()
                    setDeleteData(row)
                    // handleCollegeDelete(row._id)
                }} fontSize={24} style={{ color: "red" }} /></span>
            </div>,
        },
    ]

    const activateCollege = async (id) => {
        try {
            const payload = {
                id: id,
                status: true
            }
            const res = await updateCollegeStatus(payload)
        } catch (error) {
            console.log(error)
        }
    }

    const deactivateCollege = async (id) => {
        try {
            const payload = {
                id: id,
                status: false
            }
            const res = await updateCollegeStatus(payload)

        } catch (error) {
            console.log(error)
        }
    }
    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === "collegename") {
            const regex = /\b\w/g;
            setcollegeForm({
                ...collegeForm, collegename: value.replace(regex, function (match) {
                    return match.toUpperCase();
                })
            })
        } else {
            setcollegeForm({ ...collegeForm, [name]: value })
        }
    }



    // modal
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setcollegeForm({
            collegename: "",
            phoneNumber: "",
            email: "",
            password: ""
        })
        setShow(false)
    };
    const handleShow = () => setShow(true);

    const fetchColleges = async () => {
        try {
            setloadingColleges(true)
            const res = await getAllColleges()
            setcollegeData(res.data.data)
            setloadingColleges(false)
            console.log(res)
        } catch (error) {
            setloadingColleges(false)
            console.log(error)
        }
    }
    useEffect(() => {
        fetchColleges()
    }, [])
    const [loadingSubmit, setloadingSubmit] = useState(false)

    const handleCollegeSubmit = async () => {
        setloadingSubmit(true)
        try {
            await PostCollege(collegeForm)
            fetchColleges()
            setcollegeForm({
                collegename: "",
                phoneNumber: "",
                email: "",
                password: ""
            })
            handleClose()
            setloadingSubmit(false)
        } catch (error) {
            setloadingSubmit(false)
            console.log(error)
        }
    }
    const handleCollegeUpdate = async () => {
        setloadingSubmit(true)
        try {
            await updateCollege(collegeForm)
            fetchColleges()
            setcollegeForm({
                collegename: "",
                phoneNumber: "",
                email: "",
                password: ""
            })
            handleClose()
            setloadingSubmit(false)
        } catch (error) {
            setloadingSubmit(false)
            console.log(error)
        }
    }
    const handleCollegeDelete = async (id) => {
        try {
            const newData = collegeData?.filter(item => item._id !== id)
            toast.success("College Deleted")
            setcollegeData(newData)
            await deleteCollege(id)
        } catch (error) {
            console.log(error)
        }
    }


    // const  showDelete, handleDeleteDecision, field, HideDeleteModal
    const [showDelete, setshowDelete] = useState(false)
    const handleshowDelete = () => {
        setshowDelete(true)
    }
    const HideDeleteModal = () => {
        setshowDelete(false)
    }
    const handleDeleteDecision = (value) => {
        if (value) {
            handleCollegeDelete(DeleteData?._id)
        } else {
            toast.error("No College Deleted")
        }
    }

    // const  showDelete, handleDeleteDecision, field, HideDeleteModal
    const [showActivate, setshowActivate] = useState(false)
    const handleshowActivate = () => {
        setshowActivate(true)
    }
    const HideDeleteActivate = () => {
        setshowActivate(false)
    }
    const handleActivateDecision = (value) => {
        if (value) {
            if (ActivateData?.status) {
                deactivateCollege(ActivateData._id)
                fetchColleges()
                toast.success("College Deactivated")
            } else {
                activateCollege(ActivateData._id)
                toast.success("College Activated")
                fetchColleges(33)
            }
            // handleCollegeDelete(DeleteData?._id)
        } else {
            toast.error("No College Activated")
        }
    }


    const csvData = collegeData?.map((row, index) => ({
        "S No.": index + 1,
        "Name": row.collegename,
        "Email": row.email,
        "Phone No.": row.phoneNumber,
        "Status": row.status ? "Active" : "Deactive"
    }));


    const [searchQuery, setsearchQuery] = useState('')

    const [FilteredData, setFilteredData] = useState([])
    useEffect(() => {
        handleSearchChange()
    }, [searchQuery, collegeData]);

    const handleSearchChange = async (e) => {
        if (searchQuery === '') {
            setFilteredData(collegeData);
        } else {
            const lowercasedQuery = searchQuery.toLowerCase();
            const filteredResults = collegeData.filter(item =>
                Object.values(item).some(
                    val => val && val.toString().toLowerCase().includes(lowercasedQuery)
                )
            );
            setFilteredData(filteredResults);
        }
    }
    return (
        <>
            <section >
                <div className="d-flex w-100 justify-content-end align-items-end">

                    <button onClick={() => {
                        handleShow()
                        setisEdit(false)
                    }} className="p-3 rounded mb-4 text-white px-4 " style={{ background: "#fea302" }}>Create College</button>
                </div>
                {
                    loadingColleges ?
                        <div className="d-flex justify-content-center">
                            <Loader />
                        </div> :
                        <>
                            <CommonTable
                                data={FilteredData}
                                columns={columns}
                                csvData={csvData}
                                setsearchQuery={setsearchQuery}
                                csvName={"College-Data"}
                            />
                        </>
                }

            </section >


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add College</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form>
                        <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
                            <Form.Label> Name of College</Form.Label>
                            <Form.Control value={collegeForm?.collegename} type="text" name="collegename" onChange={handleChange} className='border border-dark' placeholder="For eg. Indira School of Business - MBA" />
                        </Form.Group>
                        <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
                            <Form.Label> Email</Form.Label>
                            <Form.Control value={collegeForm?.email} type="email" name="email" onChange={handleChange} className='border border-dark' placeholder="Email" />
                        </Form.Group>
                        <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
                            <Form.Label> Phone Number</Form.Label>
                            <Form.Control value={collegeForm?.phoneNumber} type="tel" name="phoneNumber" onChange={handleChange} className='border border-dark' placeholder="Phone" />
                        </Form.Group>
                        <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
                            <Form.Label> Password</Form.Label>
                            <Form.Control value={collegeForm?.password} type="password" name="password" onChange={handleChange} className='border border-dark' placeholder="Password" />
                        </Form.Group>
                    </Form>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    {
                        isEdit ?
                            <Button variant="primary" onClick={handleCollegeUpdate}>
                                Update College
                                <span className='me-2'>
                                    {loadingSubmit && <Loader />}
                                </span>
                            </Button>
                            :
                            <Button variant="primary" onClick={handleCollegeSubmit}>

                                Add College
                                <span className='me-2'>
                                    {loadingSubmit && <Loader />}
                                </span>
                            </Button>
                    }
                </Modal.Footer>
            </Modal>
            <DeleteModal handleDeleteDecision={handleDeleteDecision} text={`Do you want to delete ${DeleteData?.collegename}`} showDelete={showDelete} HideDeleteModal={HideDeleteModal} />
            <DeleteModal handleDeleteDecision={handleActivateDecision} text={`Do you want to ${ActivateData?.status ? "Deactivate" : "Activate"} ${ActivateData?.collegename}`} showDelete={showActivate} HideDeleteModal={HideDeleteActivate} />
        </>
    )
}

export default College