import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "../src/store/store";
import ThemeContext from "./context/ThemeContext";
import "grapesjs/dist/css/grapes.min.css";
import { PersistGate } from "redux-persist/integration/react";
import "./css/common.css";
import { QueryClient, QueryClientProvider } from 'react-query';
import "./index.css";
const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeContext>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>

        </PersistGate>
      </ThemeContext>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
// reportWebVitals();
