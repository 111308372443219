import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import Course from "../../../utils/Course";
import { UploadForm } from "./UploadForm";
import defualtImg from "../../../../images/no-image.png";
import Multiselect from "multiselect-react-dropdown";
import { CoursePublished } from "../../../../store/actions/course";
import { Link } from "react-router-dom";
import { getAllCategory } from "../../../../services/PostsService";
import { Form } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getSingleCourse, PatchCourse, PostCourse, uploadFormData } from "../../../../services/apis";
import { MdCategory } from "react-icons/md";
import { FaLanguage } from "react-icons/fa";
import { FaRupeeSign } from "react-icons/fa";
import { SiGradleplaypublisher } from "react-icons/si";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { TiTick } from "react-icons/ti";
import { GiCrossedBones } from "react-icons/gi";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
const Publish = ({ CourseObj, setCourseObj }) => {
  const param= useParams()
  const {id}=param
  const history = useHistory()
  const { section } = useSelector((state) => state.section);
  const dispatch = useDispatch();
  const [loading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [cover, setCover] = useState("");
  const thumbnailRef= useRef()

  useEffect(() => {
    const fetch = async () => {
      try {
        const category = await getAllCategory();
        setCategories(category.data.result);
      } catch {
        toast.error("❌ Something went wrong!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    };
    fetch();
  }, []);

  // const id = window.location.pathname.split("/")[2];

  // const fetchSingleCourse = async () => {
  //   try {
  //     const res = await getSingleCourse(id);
  //     console.log(res.data.data);
  //     setName(res.data.data.name);
  //     setDescription(res.data.data.description);
  //     setPrice(res.data.data.price);
  //     setlanguage(res.data.data.language);
  //     setselectedCategory(res.data.data.category);
  //     // console.log(res)
  //     // const modifiedData = {
  //     //   courseId: res?.data?.data?._id,
  //     //   sections: res?.data?.data?.section,
  //     // };
  //     // setAllSection(res?.data?.data?.section)
  //     // setSection(res?.data?.data?.section);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   fetchSingleCourse();
  // }, []);

  const cId = window.location.pathname.split("/")[2];

  const [thumbnailLink, setThumbNailLink] = useState("");
  const { categoryItems } = useSelector((state) => state.categoryReducer);

  const { singleSessionList, multipleSessionList } = useSelector(
    (state) => state.livesession
  );

  const singleSessionListFiltered = singleSessionList.filter(
    (item) => item.courseId === cId
  );

  const allSessionDetails = multipleSessionList?.concat(
    singleSessionListFiltered
  );
  const filteredAllSessionDetails = allSessionDetails?.filter(
    (item) => item.courseId === cId
  );

  const [options, setOptions] = useState([]);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [downloadCertificate, setDownloadCertificate] = useState(null);
  const [description, setDescription] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const [categoryArray, setCategoryArray] = useState([]);
  const [session, setSession] = useState([]);

  const courseHandler = (e) => {
    const { name, value } = e.target;
    setCourseObj({ ...CourseObj, [name]: value });
  };
  // useEffect(() => {
  //   const fetch = async () => {
  //     const data = await getAllSessions();
  //     setSession(data.data.result.filter((item) => item.courseId._id === id));
  //   };
  //   fetch();
  //   return <></>;
  // }, []);
  const sessionWithData = session?.map((item) => {
    return item.data;
  });

  useEffect(() => {
    const arr = [];
    // Course.getCourseSection((resp, err) => {
    //   setDescription(resp?.courses.description);
    //   setName(resp?.courses.name);
    //   setIsPublished(resp?.courses.isPublished);
    //   setPrice(resp?.courses.price);
    //   arr.push(resp?.courses.category);
    //   console.log(arr);
    //   setCategoryArray(arr);
    //   setThumbNailLink(resp?.courses.thumbnail);
    // }, cId);
  }, []);

  const modiFiedSections = section[cId]?.map(
    ({ _id: sID, chapter, ...rest }) => ({
      sID,
      ...rest,
      chapter: chapter.map(({ _id: cID, ...rest }) => ({
        cID,
        ...rest,
      })),
    })
  );

  const submitCourse = async(value) => {
    if (!CourseObj?.price||!CourseObj?.name || !CourseObj?.description ) {
      console.log("error");
      toast.error("Please Enter All Field i.e Name, Description, Price", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    // setIsLoading(true);
try {
  await PostCourse(CourseObj)
history.push('/course')
} catch (error) {
  console.log(error)
}
  };

  const updateCourse = async(value) => {
    if (!CourseObj?.price||!CourseObj?.name || !CourseObj?.description ) {
      console.log("error");
      toast.error("Please Enter All Field i.e Name, Description, Price", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    // setIsLoading(true);
try {
  await PatchCourse(CourseObj)
history.push('/course')
} catch (error) {
  console.log(error)
}
  };

  const uploadCoursePic =async()=>{
    try {
        setIsLoading(true);
        const formData = new FormData()
        formData.append("file", CourseObj?.thumnailUrl);
        const res= await uploadFormData(formData)
        setCourseObj({...CourseObj,thumnailUrl:res.data.link})
        setIsLoading(false);
        console.log(res)
      } catch (error) {
        console.log(error)
      }
  }

  // useEffect(() => {
  //  if(typeof CourseObj?.thumnailUrl==="object"){
  //   uploadCoursePic()
  //  }
  // }, [CourseObj?.thumnailUrl])

  useEffect(() => {
    if (cover) {
      const rootUrl =
        process.env.NODE_ENV === "production"
          ? "https://uploader.viosa.in/api"
          : "https://uploader.viosa.in/api";
      const formData = new FormData();
      formData.append("file", cover);

      fetch(`${rootUrl}/v2/upload/other`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          setThumbNailLink(data.link);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [cover?.lastModified]);
  return (
    <div>
      {loading && <div id="cover-spin"></div>}
      <div className="d-flex justify-content-between">
        <div
          className="cropme col-sm-3"
          data-width="400"
          data-height="300"
          data-imagetype="coursethumb"
          data-identity="977adfd7-65af-40d6-8b03-0ef00068ae9d"
          data-accept=".jpg,.jpeg,.png"
        >
          <span className=""></span>
          <h6 className="ms-4">
Upload Status - {
    (!!CourseObj?.thumnailUrl && (typeof CourseObj?.thumnailUrl)==="string") ? 
          "Uploaded":"Pending"
}
          </h6>
          <img
            style={{
              width: "300px",
              height: "300px",
              objectFit: "contain",
            }}
            src={ !!CourseObj?.thumnailUrl? typeof CourseObj?.thumnailUrl === "object"
                                ? URL.createObjectURL(CourseObj?.thumnailUrl)
                                : CourseObj?.thumnailUrl:defualtImg}
            className="mb-1"
            onError={(e) => {
              e.target.src = defualtImg;
              e.onerror = null;
            }}
          />
          <div className="d-flex justify-content-center mt-2 gap-4 ">
          <button className="bg-primary p-2 px-3 text-white" onClick = {()=>thumbnailRef.current.click()}>Choose </button>
          <button className="bg-primary p-2 px-3 text-white" onClick = {()=>uploadCoursePic()}>Upload </button>
            <input className="d-none" ref={thumbnailRef} type="file" onChange={(e)=>setCourseObj({...CourseObj,thumnailUrl:e.target.files[0]})} />
            {/* <UploadForm
              className="mt-2"
              isImage={true}
              isPreview={false}
              cover={cover}
              setCover={setCover}
            /> */}
          </div>
        </div>
        <div
          className="col-sm-7 col-md-9 align-self-center"
          style={{ marginLeft: 200 }}
        >
        <div className="my-4 d-flex align-items-center gap-4">
              <h3 className="mb-0">Is tool?</h3>
              <input
                      type="checkbox"
                      className="form-check-input"
                      checked={CourseObj?.isTool}
                  onClick={()=>setCourseObj({...CourseObj,isTool:CourseObj?.isTool?false:true})}/>
            </div>
          <h4 className="card-title mt-3">Course Name <span className="text-danger">*</span> </h4>
          <div className="form-group bmd-form-group is-filled">
            <input
              type="text"
              className="form-control w-75 s_coursecurriculumsetting coursename"
              maxlength="100"
              name="name"
              placeholder="Course name"
              value={CourseObj?.name || ""}
              onChange={(e) => courseHandler(e)}
            />
            <div className="mt-3 col-9">
              <h5>Add Description <span className="text-danger">*</span></h5>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1 border border-dark w-100"
              >
                {/* <Form.Label>Job Description</Form.Label> */}
                <ReactQuill
                  theme="snow"
                  value={CourseObj?.description || ""}
                  placeholder="write the description of job"
                  className="w-100"
                  name="description"
                  onChange={(value) => {
                    setCourseObj({ ...CourseObj, description: value });
                  }}
                />
              </Form.Group>
            </div>
          
            {/* <textarea
              className="form-control w-50 s_coursecurriculumsetting coursename"
              maxlength="100"
              style={{ height: 200 }}
              placeholder="Enter Description here..."
              onChange={(e) => setDescription(e.target.value)}
              name="description"
              value={description || ""}
            /> */}
          </div>
        </div>
      </div>
      <div className="card-body px-0">
        <div className="row">
          {/* <div className="col-md-6">
            <div className="card">
              <div className="card-header card-header-rose card-header-icon card-avatar">
                <div className="card-icon">
                  <i class="fa-solid fa-pen-to-square"></i>
                </div>
                <h4 className="card-title">Course Category</h4>
              </div>
              <div className="card-body">
                <div className="s_coursecurriculumsetting">Choose Category</div>
                <Multiselect
                  options={categories.map((item) => item.name)}
                  isObject={false}
                  selectedValues={CourseObj?.category}
                  onSelect={(e) => setCourseObj({ ...CourseObj, category: e })}
                  onRemove={(e) => console.log(e)}
                  name="category"
                />
                <select
                  className="s_coursecurriculumsetting  w-100 language"
                  data-style="btn select-with-transition"
                  data-size="5"
                  tabindex="-98"
                  value={language}
                  onChange={(e) => setlanguage(e.target.value)}
                >
                  <option disabled >
                    Select Language
                  </option>
                  <option value="English" >
                    English
                  </option>
                  <option value="Hindi">Hindi</option>
                  <option value="Tamil">Tamil</option>
                  <option value="Bengali">Bengali</option>
                  <option value="Marathi">Marathi</option>
                  <option value="Telugu">Telugu</option>
                  <option value="Gujarati">Gujarati</option>
                  <option value="Urdu">Urdu</option>
                  <option value="Kannada">Kannada</option>
                  <option value="Odia">Odia</option>
                  <option value="Malayalam">Malayalam</option>
                  <option value="Punjabi">Punjabi</option>
                  <option value="Sign Language">Sign Language</option>
                </select>
              </div>
              <p>
                <Link to="/category">Create New Category</Link>
              </p>
            </div>
          </div> */}
          <div className="col-md-6">
            <div className="card">
              <div className="card-header card-header-rose card-header-icon card-avatar">
                <div className="card-icon d-flex justify-content-center align-items-center">
                  <MdCategory fontSize={24} />
                </div>
                <h4 className="card-title">Select Category</h4>
              </div>
              <div className="card-body">
                <div className="s_coursecurriculumsetting">
                  <select
                    className="s_coursecurriculumsetting  w-100 language"
                    data-style="btn select-with-transition"
                    data-size="5"
                    tabindex="-98"
                    value={CourseObj?.category}
                    onChange={(e) =>
                      setCourseObj({ ...CourseObj, category: e.target.value })
                    }
                  >
                    <option >Select Category</option>
                    {categories?.map((item) => {
                      return <option value={item?.name}>{item?.name}</option>;
                    })}
                    {/* <option value="English" >
                      English
                    </option>
                    <option value="Hindi">Hindi</option> */}
                    {/* <option value="Tamil">Tamil</option>
                    <option value="Bengali">Bengali</option>
                    <option value="Marathi">Marathi</option>
                    <option value="Telugu">Telugu</option>
                    <option value="Gujarati">Gujarati</option>
                    <option value="Urdu">Urdu</option>
                    <option value="Kannada">Kannada</option>
                    <option value="Odia">Odia</option>
                    <option value="Malayalam">Malayalam</option>
                    <option value="Punjabi">Punjabi</option>
                    <option value="Sign Language">Sign Language</option> */}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card">
              <div className="card-header card-header-rose card-header-icon card-avatar">
                <div className="card-icon d-flex justify-content-center align-items-center">
                  <FaLanguage fontSize={24} />
                </div>
                <h4 className="card-title">Course language</h4>
              </div>
              <div className="card-body">
                <div className="s_coursecurriculumsetting">
                  <select
                    className="s_coursecurriculumsetting  w-100 language"
                    data-style="btn select-with-transition"
                    data-size="5"
                    tabindex="-98"
                    name="language"
                    onChange={courseHandler}
                    value={CourseObj?.language}
                  >
                    <option >Select Language</option>
                    <option value="English"> English</option>
                    <option value="Hindi">Hindi</option>
                    <option value="Tamil">Tamil</option>
                    <option value="Bengali">Bengali</option>
                    <option value="Marathi">Marathi</option>
                    <option value="Telugu">Telugu</option>
                    <option value="Gujarati">Gujarati</option>
                    <option value="Urdu">Urdu</option>
                    <option value="Kannada">Kannada</option>
                    <option value="Odia">Odia</option>
                    <option value="Malayalam">Malayalam</option>
                    <option value="Punjabi">Punjabi</option>
                    <option value="Sign Language">Sign Language</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-header card-header-rose card-header-icon card-avatar">
                <div className="card-icon d-flex justify-content-center align-items-center">
                <FaRupeeSign fontSize={24}/>
                </div>
                <h4 className="card-title">INR Price <span className="text-danger">*</span>     </h4>
              </div>
              <div className="card-body">
                <span className="bmd-form-group is-filled">
                  <div className="input-group mb-3">
                    {/* <div className="input-group-prepend bg-secondary">
                      <span className="input-group-text text-light">₹</span>
                    </div> */}
                    <input
                      type="number"
                      min="0"
                      placeholder="0"
                      name="price"
                      value={CourseObj?.price}
                      step="0.01"
                      maxlength="11"
                      max="500000"
                      onChange={courseHandler}
                      // onkeypress="if (this.value.length == this.maxLength) { return false } else { return validateNumbertwodecimal(event,this.value, this.selectionStart) }"
                      // onkeyup="this.value = numberMinMaxValidator(this.value, 0,500000)"
                      className="form-control pl-2 text-dark s_coursecurriculumsetting inrprice"
                    // value="0.00"
                    />
                  </div>
                </span>
                <div>
                  Please note that changing the course price will disable all
                  the issued coupons. Please recreate the coupon codes again.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
          
          <div className="card">
          <div className="card-header card-header-rose card-header-icon card-avatar">
                <div className="card-icon d-flex justify-content-center align-items-center">
                <SiGradleplaypublisher fontSize={24}/>
                </div>
                <h4 className="card-title">Pubish or Unpublished</h4>
              </div>
              <div className="card-body">
                <span className="bmd-form-group is-filled">
                <div className="s_coursecurriculumsetting">
                <select
                    className="s_coursecurriculumsetting  w-100 language"
                    data-style="btn select-with-transition"
                    data-size="5"
                    tabindex="-98"
                    value={CourseObj?.isPublished}
                    onChange={(e) =>
                      setCourseObj({ ...CourseObj, isPublished: e.target.value==="true"? true:false })
                    }
                  >
                    <option >Select Status</option>
                    <option value={true}>Published</option>
                    <option value={false}>Unpublished</option>
                   </select>
              </div>
                </span>
              </div>
          </div>
            </div>
          {/* <div className="col-md-6">
            <div className="card">
              <div className="card-header card-header-rose card-header-icon card-avatar">
                <div className="card-icon">
                  <i class="fa-solid fa-pen-to-square"></i>
                </div>
                <h4 className="card-title">USD Price</h4>
              </div>
              <div className="card-body">
                <span className="bmd-form-group is-filled">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend bg-secondary">
                      <span className="input-group-text text-light">$</span>
                    </div>
                    <input
                      type="text"
                      min="0"
                      step="0.01"
                      maxlength="11"
                      max="5000"
                      // onkeypress="if (this.value.length == this.maxLength) { return false } else { return validateNumbertwodecimal(event,this.value, this.selectionStart) }"
                      // onkeyup="this.value = numberMinMaxValidator(this.value, 0,5000)"
                      className="form-control pl-2 text-dark s_coursecurriculumsetting usdprice"
                    // value="0.00"
                    />
                  </div>
                </span>
                <div>
                  Please note that changing the course price will disable all
                  the issued coupons. Please recreate the coupon codes again.
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-header card-header-rose card-header-icon card-avatar">
                <div className="card-icon">
                  <i class="fa-solid fa-pen-to-square"></i>
                </div>
                <h4 className="card-title">Enable chat</h4>
              </div>
              <div className="card-body">
                <div className="togglebutton w-100 p-0">
                  <label className="text-dark">
                    <input
                      type="checkbox"
                      className="s_coursecurriculumsetting s_enablechat"
                      checked=""
                    />
                    <span className="toggle ml-0"></span>
                    Enable chat
                  </label>
                </div>
                <div>
                  If you turn this OFF, learner chat will be disabled for this
                  course.
                </div>
              </div>
            </div>
          </div> */}
        </div>

        {/* <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-header card-header-rose card-header-icon card-avatar">
                <div className="card-icon">
                  <i class="fa-solid fa-pen-to-square"></i>
                </div>
                <h4 className="card-title">Download Certificate</h4>
              </div>
              <div className="card-body">
                <div className="togglebutton w-100 p-0">
                  <label className="text-dark">
                    <input
                      onChange={(e) => setDownloadCertificate(e)}
                      type="checkbox"
                      className="s_coursecurriculumsetting s_enablechat"
                    />
                    <span className="toggle ml-0"></span>
                    Download Certificate
                  </label>
                </div>
                <div>
                  If you turn this OFF, learner will not able to download
                  certificate
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="mt-2">
        {id!=="add" ? (
          <>

            <button
              className="btn btn-primary mx-4"
              onClick={() => updateCourse()}
            >
              Update Course
            </button>
          </>
        ) : (
          <>
            <button
              className="btn btn-primary"
              onClick={() => submitCourse(true)}
            >
              Submit Course
            </button>
            {/* <button className="btn btn-primary ms-3">Add Course</button> */}
          </>
        )}
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
};

export default Publish;
