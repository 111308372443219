import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import MultiSelectCourses from '../../../../../Common/Components/MultiSelectCourses';
import ReactQuill from 'react-quill';
import { getAllCourses, getSingleBundle, PostBundle, PutBundle } from '../../../../../services/apis';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';

const AddBundle = () => {
const {id}=useParams()
  const history = useHistory()
  const [allCourses, setallCourses] = useState([]);
  const [bundleObj, setbundleObj] = useState({
    name: "",
    description: "",
    price: "",
    course: []
  });

  const handleChange = (e) => {
    const { name, value } = e.target
    setbundleObj({ ...bundleObj, [name]: value })
  }


  const fetchCourses = async () => {
    try {
      const res = await getAllCourses()
      console.log(res)
      setallCourses(res.data.courses)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchCourses()
  }, [])


  const handleMultiSelectCourseChange = (e) => {
    const { name, value } = e.target
    setbundleObj({ ...bundleObj, course: [...bundleObj?.course, value] })
  }
  const removeMultiSelectCourseChange = (e, id) => {
    e.preventDefault()
    const newArr = bundleObj?.course?.filter((item) => item != id)
    setbundleObj({ ...bundleObj, course: newArr })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const res = await PostBundle(bundleObj)
      history.push('/course')
    } catch (error) {
      console.log(error)
    }
  }

    const handleUpdate = async (e) => {
    e.preventDefault()
    try {
      const res = await PutBundle(bundleObj)
      history.push('/course')
    } catch (error) {
      console.log(error)
    }
  }


 const fetchSingleBundle = async()=>{
  try {
    const res = await getSingleBundle(id)
    console.log(res)
    setbundleObj(res.data.newBundle)
  } catch (error) {
    console.log(error)
  }
 }
 useEffect(() => {
  fetchSingleBundle()
 }, [])

  return (
    <>
      <div className='w-100 d-flex justify-content-center'>
        <Form className="w-75  bg-white p-5 rounded-5">
          <Form.Group className="mb-4 " controlId="exampleForm.ControlInput1">
            <Form.Label>Bundle Name <span className="text-danger">*</span></Form.Label>
            <Form.Control type="text" name="name" value={bundleObj?.name} onChange={handleChange} className='border border-dark' placeholder="Bundle Name" />
          </Form.Group>
          <div className="mb-4 ">
            <Form.Group
              className=""
              controlId="exampleForm.ControlInput1 border border-dark w-100"
            >
              <Form.Label>Description </Form.Label>
              {/* <Form.Label>Job Description</Form.Label> */}
              <ReactQuill
                theme="snow"
                value={bundleObj?.description || ""}
                placeholder="write the description "
                className="w-100"
                name="description"
                onChange={(value) => {
                  setbundleObj({ ...bundleObj, description: value });
                }}
              />
            </Form.Group>
          </div>
          <Form.Group className=" mb-4 " controlId="exampleForm.ControlInput1">
            <Form.Label>Price</Form.Label>
            <Form.Control type="number" min="0" name="price" value={bundleObj?.price} onChange={handleChange} className='border border-dark' placeholder="Price" />
          </Form.Group>

          <Form.Group className=" " controlId="exampleForm.ControlInput1">
            <Form.Label>Add Courses</Form.Label>
            <MultiSelectCourses handleMultiSelectChange={handleMultiSelectCourseChange} selectName="course" removeMultiSelectChange={removeMultiSelectCourseChange} placeholder="Add Courses" selectedData={bundleObj?.course} data={allCourses} />
          </Form.Group>
                {
                  !!id ? 
          <button className='btn-primary mt-3 p-2 px-4 rounded-3' onClick={handleUpdate}>Update</button>
                  : 
          <button className='btn-primary mt-3 p-2 px-4 rounded-3' onClick={handleSubmit}>Submit</button>
                }
        </Form>


      </div>
    </>
  );
}

export default AddBundle;
