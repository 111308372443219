import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import "./addcourse.css";
import { IoMdAdd } from "react-icons/io";
import { MdDeleteOutline } from "react-icons/md";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Assignment from "./Assignment";
import { uploadFormData, uploadPDFData } from "../../../../../services/apis";

function AddCourse({ setCourseObj, CourseObj }) {
  const [newSectionName, setNewSectionName] = useState("");
  const [newChapterName, setNewChapterName] = useState("");
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(null);
  const [isUploading, setIsUploading] = useState({}); // Store upload status for each chapter
  const [uploadStatus, setUploadStatus] = useState({}); // Store status (Pending, Uploaded, etc.)

const addSection = () => {
    setCourseObj((prev) => ({
      ...prev,
      section: [
        ...CourseObj?.section,
        { sectionName: newSectionName, chapter: [] },
      ],
    }));
    setNewSectionName("");
  };

  const editSection = (index, newName) => {
    const updatedSection = CourseObj.section.map((section, i) =>
      i === index ? { ...section, sectionName: newName } : section
    );
    setCourseObj((prev) => ({ ...prev, section: updatedSection }));
  };

  const removeSection = (index) => {
    const updatedSections = CourseObj.section.filter((_, i) => i !== index);
    setCourseObj((prev) => ({ ...prev, section: updatedSections }));
  };

  const addChapterToSection = (sectionIndex) => {
    const newChapter = {
      chapterName: newChapterName,
      chapterID: Math.random().toString(36).substr(2, 9), // Generate a random ID
      sectionID: sectionIndex,
      videoLink: "",
      pdfLink: "",
      assessment: [],
      url: "",
      selectFeature: 1,
    };

    const updatedSections = CourseObj?.section?.map((section, i) =>
      i === sectionIndex
        ? { ...section, chapter: [...section.chapter, newChapter] }
        : section
    );
    setCourseObj((prev) => ({ ...prev, section: updatedSections }));
    setNewChapterName("");
  };

  const removeChapterFromSection = (sectionIndex, chapterIndex) => {
    const updatedSections = CourseObj.section.map((section, i) =>
      i === sectionIndex
        ? {
            ...section,
            chapter: section.chapter.filter((_, j) => j !== chapterIndex),
          }
        : section
    );
    setCourseObj((prev) => ({ ...prev, section: updatedSections }));
  };

  const handleOptionChange = (sectionIndex, chapterIndex, option) => {
    const selectFeatureMap = {
      Video: 1,
      PDF: 2,
      URL: 3,
      Assignment: 4,
      PageBuilder: 5,
    };

    const updatedSections = CourseObj.section.map((section, i) =>
      i === sectionIndex
        ? {
            ...section,
            chapter: section.chapter.map((chapter, j) =>
              j === chapterIndex
                ? { ...chapter, selectFeature: selectFeatureMap[option] }
                : chapter
            ),
          }
        : section
    );
    console.log(option);
    setCourseObj((prev) => ({ ...prev, section: updatedSections }));
  };

  const handleInputChange = (sectionIndex, chapterIndex, field, value) => {
    console.log(typeof value);
    const updatedSections = CourseObj?.section?.map((section, i) =>
      i === sectionIndex
        ? {
            ...section,
            chapter: section.chapter.map((chapter, j) =>
              j === chapterIndex ? { ...chapter, [field]: value } : chapter
            ),
          }
        : section
    );
    setCourseObj((prev) => ({ ...prev, section: updatedSections }));
  };






  useEffect(() => {
    const updatedSections = CourseObj.section.map((section) => ({
      ...section,
      chapter: section.chapter.map((chapter) => {
        let selectFeature = 0;
        if (chapter.videoLink) selectFeature = 1;
        else if (!!chapter.pdfLink) selectFeature = 2;
        else if (!!chapter.url) selectFeature = 3;
        else if (!!chapter.assessment && chapter.assessment.length > 0) selectFeature = 4;
        else if (!!chapter.pageContent) selectFeature = 5;

        return { ...chapter, selectFeature };
      }),
    }));
    setCourseObj((prev) => ({ ...prev, section: updatedSections }));
    // if(!!id){
    // }
      
  }, []);





  

const handleUpload = async (file, sectionIndex, chapterIndex, field,selectFeature) => {
   setIsUploading((prev) => ({
      ...prev,
      [`${sectionIndex}-${chapterIndex}-${field}`]: true,
    }));
  const formData= new FormData();
  formData.append("file", file);
  let res;
  if(field ==="pdfLink"){
    res = await uploadPDFData(formData);
  }else{
    res = await uploadFormData(formData);
  }
  console.log(res)
  if (!res) return;
  const updatedSections = CourseObj.section.map((section, i) =>
    i === sectionIndex
      ? {
          ...section,
          chapter: section.chapter.map((chapter, j) =>
            j === chapterIndex ? { ...chapter, [field]: res.data.link } : chapter
          ),
        }
      : section
  );

  setCourseObj((prev) => ({ ...prev, section: updatedSections }));
  setUploadStatus((prev) => ({
      ...prev,
      [`${sectionIndex}-${chapterIndex}-${field}`]: "Uploaded",
    }));
    setIsUploading((prev) => ({
      ...prev,
      [`${sectionIndex}-${chapterIndex}-${field}`]: false,
    }));
};
  return (
    <>
      <div>

        <h3>Sections</h3>
            
        {CourseObj?.isTool ? "":
          CourseObj?.section?.map((section, sectionIndex) => (
          <div
            key={sectionIndex}
            className=" section-container rounded-4   mt-4"
          >
            <div className="section-header mb-3 d-flex justify-content-between ">
              <span className=" py-2 px-3 rounded-right text-right">
                {sectionIndex + 1}
              </span>
              <button
                onClick={() => removeSection(sectionIndex)}
                className="bg-danger p-2 "
              >
                <MdDeleteOutline color="white" fontSize={26} />
              </button>
            </div>
            <div className="d-flex justify-content-between w-100 gap-5 mt-4">
              <input
                type="text"
                className="outline-none w-100 section-input"
                placeholder="Section Name"
                name="sectionName"
                value={section.sectionName}
                onChange={(e) => editSection(sectionIndex, e.target.value)}
              />
              <button
                onClick={() => addChapterToSection(sectionIndex)}
                className="w-25 p-2"
              >
                Add Chapter
              </button>
            </div>

            {/* <input
              type="text"
              className="section-input"
              value={newChapterName}
              onChange={(e) => setNewChapterName(e.target.value)}
              placeholder="Chapter Name"
            />
            

            <h5>Chapters</h5> */}
            {
              section?.chapter?.map((chapter, chapterIndex) => (
              <div
                key={chapterIndex}
                className="mt-4 border-light border  rounded-3 d-flex justify-content-between flex-column p-3"
              >
                <div className="d-flex justify-content-between gap-4">
                  <input
                    type="text"
                    className="outline-none w-100 section-input"
                    placeholder="Chapter Name"
                    value={chapter.chapterName}
                    onChange={(e) =>
                      handleInputChange(
                        sectionIndex,
                        chapterIndex,
                        "chapterName",
                        e.target.value
                      )
                    }
                  />
                  {/* <span>{chapter.chapterName}</span> */}
                  <button
                    className="bg-danger p-2 "
                    onClick={() =>
                      removeChapterFromSection(sectionIndex, chapterIndex)
                    }
                  >
                    <MdDeleteOutline color="white" fontSize={26} />
                  </button>
                </div>

                <div className=" d-flex justify-content-between gap-5">
                  <div className="mt-4 d-flex w-100 justify-content-between gap-5">
                    {["Video", "PDF", "URL", "Assignment", "PageBuilder"].map(
                      (option) => (
                        <div
                          key={option}
                          className="border text-center border-light rounded-3 p-3 w-100"
                          style={{
                            cursor: "pointer",
                            background:
                              chapter.selectFeature ===
                              (option === "Video"
                                ? 1
                                : option === "PDF"
                                ? 2
                                : option === "URL"
                                ? 3
                                : option === "Assignment"
                                ? 4
                                : 5)
                                ? "#521986"
                                : "",
                            color:
                              chapter.selectFeature ===
                              (option === "Video"
                                ? 1
                                : option === "PDF"
                                ? 2
                                : option === "URL"
                                ? 3
                                : option === "Assignment"
                                ? 4
                                : 5)
                                ? "white"
                                : "black",
                          }}
                          onClick={() =>
                            handleOptionChange(
                              sectionIndex,
                              chapterIndex,
                              option
                            )
                          }
                        >
                          {option}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="w-100 d-flex justify-content-center mt-4 border border-light rounded-3 p-3">
                  {chapter?.selectFeature === 1 && (
                    <div className="my-3 w-100 d-flex flex-column gap-4">
                      {/* <label>Upload Video:</label> */}
                      <div>
                         Upload Status:{" "}
                         {
                         (!chapter?.videoLink || typeof chapter?.videoLink ==="object") ?"Pending" :  
                         (!!chapter?.videoLink && typeof chapter?.videoLink ==="string") && "Uploaded"
                         }
                      </div>
                      <div className="d-flex justify-content-between gap-4">
                      <input
                        type="file"
                        accept="video/*"
                       
                        onChange={(e) =>
                          handleInputChange(
                            sectionIndex,
                            chapterIndex,
                            "videoLink",
                            e.target.files[0]
                          )
                        }
                      />
                      <button  disabled={isUploading[`${sectionIndex}-${chapterIndex}-videoLink`]} className="p-2 btn-primary rounded-2" onClick={() => handleUpload(chapter.videoLink, sectionIndex, chapterIndex, "videoLink",chapter?.selectFeature)}>
                      {isUploading[`${sectionIndex}-${chapterIndex}-videoLink`] ? (
                            "Uploading..."
                          ) : (
                            "Upload Video"
                          )}
                      </button>
                      </div>
                      {!!chapter?.videoLink && (
                        <video width="400" controls>
                          <source
                            src={
                              typeof chapter?.videoLink === "object"
                                ? URL.createObjectURL(chapter?.videoLink)
                                : chapter?.videoLink
                            }
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      )}
                    </div>
                  )}
                  {chapter?.selectFeature === 2 && (
                    <div className="my-3 w-100">
                    <div className="mb-3">
                         Upload Status:{" "}
                         {
                         (!chapter?.pdfLink || typeof chapter?.pdfLink ==="object") ?"Pending" :  
                         (!!chapter?.pdfLink && typeof chapter?.pdfLink ==="string") && "Uploaded"
                         }
                      </div>
                      <div className="d-flex justify-content-between gap-4">

                      
                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={(e) =>
                          handleInputChange(
                            sectionIndex,
                            chapterIndex,
                            "pdfLink",
                            e.target.files[0]
                          )
                        }
                      />
                      <button  disabled={isUploading[`${sectionIndex}-${chapterIndex}-pdfLink`]} className="p-2 btn-primary rounded-2" onClick={() => handleUpload(chapter.pdfLink, sectionIndex, chapterIndex, "pdfLink",chapter?.selectFeature)}>
                      {isUploading[`${sectionIndex}-${chapterIndex}-pdfLink`] ? (
                            "Uploading..."
                          ) : (
                            "Upload PDF"
                          )}
                      </button>
                      </div>
                      {!!chapter?.pdfLink && (
                        <div key={chapter?.pdfLink} className="pdf-preview mt-4">
            <embed
            src={
            typeof chapter?.pdfLink === "object"
              ? URL.createObjectURL(chapter?.pdfLink)
              : `${chapter?.pdfLink}#toolbar=0`
          }
            // src={"https://viosa-storage-2.s3.amazonaws.com/20241105T175241294Z20241105T173211543Z20241104T122417948ZVISHAL-HANDA-RESUME-2%20%281%29%20%2812%29.pdf"
            // typeof chapter?.pdfLink === "object"
            //   ? URL.createObjectURL(chapter?.pdfLink)
            //   : `${chapter?.pdfLink}#toolbar=0`
          // }
          type="application/pdf"
          width="100%"
          height="400px"
        />
 
{/* <iframe
          src={chapter?.pdfLink}
          width="100%"
          height="500px"
          title="PDF Preview"
          frameBorder="0"
          allow="autoplay; encrypted-media"
        ></iframe> */}
                        </div>
                      )}
                    </div>
                  )}
                  {chapter?.selectFeature === 3 && (
                    <div className="my-3 w-100">
                      {/* <label>Enter URL:</label> */}
                      <input
                        type="text"
                        placeholder="Enter URL"
                        className="p-2 w-100"
                        value={chapter?.url}
                        onChange={(e) =>
                          handleInputChange(
                            sectionIndex,
                            chapterIndex,
                            "url",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  )}
                  {chapter?.selectFeature === 4 && (
                    <>
                      <div className="my-3 w-100">
                        {/* <label>Assignment Questions:</label> */}
                        {/* Add custom Assignment inputs here */}
                        <div className="assignment-section ">
                          <Assignment
                            CourseObj={CourseObj}
                            setCourseObj={setCourseObj}
                            chapter={chapter}
                            sectionIndex={sectionIndex}
                            chapterIndex={chapterIndex}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {chapter?.selectFeature === 5 && (
                    <div className="my-3 w-100">
                      <label>Page Builder (Text Editor):</label>
                      <ReactQuill
                        theme="snow"
                        style={{ width: "100%" }}
                        className="w-100"
                        onChange={(value) =>
                          handleInputChange(
                            sectionIndex,
                            chapterIndex,
                            "pageContent",
                            value
                          )
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        ))}

        {/* <h2>Add Section</h2> */}
        <div className="w-100 d-flex justify-content-center mt-5">
          <div className="d-flex gap-3 justify-content-center align-items-center">
            {/* <Form.Group
              className="mb-3 w-100"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Section Name</Form.Label>
              <Form.Control
                type="text"
                name="jobtitle"
                value={newSectionName}
                onChange={(e) => setNewSectionName(e.target.value)}
                className="border w-100 border-dark"
                placeholder="Section Name"
              />
            </Form.Group> */}
            {
              CourseObj?.isTool ? "":
            <button onClick={addSection} className="mt-3 bg-primary text-white rounded-3 p-2 px-3">
             Add Section <IoMdAdd fontSize={25} />
            </button>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default AddCourse;
