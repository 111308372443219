import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { MdDelete, MdModeEdit } from 'react-icons/md'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form, Spinner } from 'react-bootstrap'
import Loader from '../Common/Loader/Loader';
import CommonTable from '../Common/Table/CommonTable';
import { getAllCollegesStudents, getAllInterview } from '../services/apis';

function Students() {
    const [studentsData, setstudentsData] = useState([])
    const [loading, setloading] = useState(false)
    const navigate = useHistory()

    const fetchStudents = async () => {
        try {

            setloading(true)
            const query = {
                params: {
                    collegeid: localStorage.getItem("userId")
                }
            }
            const res = await getAllCollegesStudents(query)
            console.log(res)
            setstudentsData(res.data.data)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log(error)
        }
    }

    useEffect(() => {
        fetchStudents()
    }, [])




    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            grow: 2
        },
        {
            name: 'Progress',
            selector: row => row.email,
            sortable: true
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true
        },
        {
            name: 'Phone Number',
            selector: row => row.phoneNumber,
            sortable: true
        }
    ]


    // deletion 


    // <----------------------------------------delete batch-------------------------------------------->

    const [showDelete, setshowDelete] = useState(false)
    const [DeletionObject, setDeletionObject] = useState()

    const ShowDeleteModal = (value) => {
        setDeletionObject(value)
        setshowDelete(true)
    }
    const HideDeleteModal = () => {
        setshowDelete(false)
    }

    // const handleDeleteDecision = async (value) => {
    //     try {
    //         if (value === true) {
    //             const newArr = InterviewsData?.filter((item, index) => item._id !== DeletionObject?._id)
    //             setInterviewsData(newArr)
    //             await DeleteInterviewById(DeletionObject?._id)
    //         }
    //         console.log(value)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }
    const [searchQuery, setsearchQuery] = useState('')
    const [filteredData, setFilteredData] = useState();

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredData(studentsData);
        } else {
            const lowercasedQuery = searchQuery.toLowerCase();
            const filteredResults = studentsData.filter(item =>
                item.name.toLowerCase().includes(lowercasedQuery) ||
                item.email.toLowerCase().includes(lowercasedQuery) ||
                item.phoneNumber.includes(lowercasedQuery)
            );
            setFilteredData(filteredResults);
        }
    }, [searchQuery, studentsData]);

    const csvData = studentsData?.map((row, index) => ({
        "S No.": index + 1,
        "Name": row.name,
        "Email": row?.email,
        "Phone": row.phoneNumber,
    }));

    return (
        <div>
            <div className="d-flex w-100 gap-4 mb-3  align-items-center">
                {/* <input type="search" placeholder='Search Students' onChange={(e) => setsearchQuery(e.target.value)} className='p-3 rounded-4 w-25' /> */}
                {/* <button className="px-4 p-3 rounded common-btn  8text-white px-4 " >Search</button> */}
            </div>


            {
                loading ? <Loader /> : <CommonTable data={filteredData}

                    columns={columns} csvData={csvData} csvName={"Students-Data"} />

            }
            {/* <DeleteModal DeletionObject={DeletionObject} showDelete={showDelete} ShowDeleteModal={ShowDeleteModal} handleDeleteDecision={handleDeleteDecision} HideDeleteModal={HideDeleteModal} field={DeletionObject?.jobtitle} /> */}

        </div>
    )
}

export default Students