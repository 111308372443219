import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import profile from "../../images/viosa.png";
import { ThemeContext } from "../../context/ThemeContext";
import { CiUser } from "react-icons/ci";
import { AiOutlineUserAdd } from "react-icons/ai";
import { SiGoogledocs } from "react-icons/si";
import { GoPencil } from "react-icons/go";
import { MdOutlineSchedule } from "react-icons/md";
import { FiUserCheck } from "react-icons/fi";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';



const InterviewCollege = () => {
  const { changeBackground } = useContext(ThemeContext);
  const [orders, setOrders] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [users, setUsers] = useState([]);
  const [support, setSupport] = useState(0);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const history = useHistory();

  const fixedOrders = orders.slice(0, 5);

  useEffect(() => {
    const fetch = async () => {
      const response = await getOrders();
      setOrders(response.data.result);
      const { data } = await getCollab();
      const data2 = await getSupport();
      const data3 = await getCareer();
      setSupport(
        data?.result?.length +
        data2?.data?.result?.length +
        data3?.data?.result?.length
      );
    };
    fetch();
    const fetchCourses = async () => {
      await getCoursesList().then((response) => {
        setCourseList(response.data.courses);
      });
    };
    fetchCourses();
    const fetchUsers = async () => {
      try {
        const response = await getUsers();
        setUsers(response.data.result);
      } catch (e) {
        console.log(e);
      }
    };
    fetchUsers();
  }, []);
  const totalEarning = orders?.reduce((sum, item) => {
    return sum + item.amount;
  }, 0);
  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
  }, []);
  var now = new Date().getDate();

  var datas = orders?.filter(
    (p) => Number(p.createdAt.split("T")[0].split("-")[2]) === now
  );
  const dayEaring = datas.reduce((sum, item) => {
    return sum + item.amount;
  }, 0);

  const [value, onChange] = useState(new Date());
  return (
    <>
      <section>
        <div className="d-flex w-100 justify-content-end">
          <button className="p-3 rounded mb-4 text-white px-4 " onClick={handleShow} style={{ background: "#521986 " }}>Add College</button>
        </div>
        <table className="table  display border-1">
          <thead>
            <tr>

              <th style={{ background: "#521986", color: "#fff" }}>
                Role
              </th>
              <th style={{ background: "#521986", color: "#fff" }}>
                Job Location
              </th>
              <th style={{ background: "#521986", color: "#fff" }}>
                Created On
              </th>
              <th style={{ background: "#521986", color: "#fff" }}>
                Expiry Date
              </th>
              <th style={{ background: "#521986", color: "#fff" }}>
                Responses
              </th>
              <th style={{ background: "#521986", color: "#fff" }}>
                Status
              </th>
              <th style={{ background: "#521986 ", color: "#fff" }}>
                Share
              </th>
              <th style={{ background: "#521986", color: "#fff" }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody className="">
            {/* {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className="active-hover active-hover-fw-bold cursor-pointer"
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })} */}
          </tbody>
        </table>
      </section>


      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add College</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Full name" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="abc@gmail.com" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="" />
              </Form.Group>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Add College
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};


export default InterviewCollege;



