import React from "react";
import { Form } from "react-bootstrap";
import { MdDeleteOutline } from "react-icons/md";

const Assignment = ({
  CourseObj,
  setCourseObj,
  chapter,
  sectionIndex,
  chapterIndex,
}) => {
  const addAssignment = (sectionIndex, chapterIndex) => {
    const newAssignment = {
      question: "",
      correctAnswer: "",
      hint: "",
      options: [],
    };
    const updatedSections = CourseObj.section.map((section, i) =>
      i === sectionIndex
        ? {
            ...section,
            chapter: section.chapter.map((chapter, j) =>
              j === chapterIndex
                ? {
                    ...chapter,
                    assessment: [...chapter.assessment, newAssignment],
                  }
                : chapter
            ),
          }
        : section
    );
    setCourseObj((prev) => ({ ...prev, section: updatedSections }));
  };

  const removeAssignment = (sectionIndex, chapterIndex, assignmentIndex) => {
    const updatedSections = CourseObj.section.map((section, i) =>
      i === sectionIndex
        ? {
            ...section,
            chapter: section.chapter.map((chapter, j) =>
              j === chapterIndex
                ? {
                    ...chapter,
                    assessment: chapter.assessment.filter(
                      (_, k) => k !== assignmentIndex
                    ),
                  }
                : chapter
            ),
          }
        : section
    );
    setCourseObj((prev) => ({ ...prev, section: updatedSections }));
  };

  const handleAssignmentChange = (
    sectionIndex,
    chapterIndex,
    assignmentIndex,
    field,
    value
  ) => {
    const updatedSections = CourseObj.section.map((section, i) =>
      i === sectionIndex
        ? {
            ...section,
            chapter: section.chapter.map((chapter, j) =>
              j === chapterIndex
                ? {
                    ...chapter,
                    assessment: chapter.assessment.map((assignment, k) => {
                      if (k === assignmentIndex) {
                        // Handle option change specifically
                        if (field.startsWith("options.")) {
                          const optionIndex = parseInt(field.split(".")[1]);
                          const updatedOptions = [...assignment.options];
                          updatedOptions[optionIndex] = value;
                          return { ...assignment, options: updatedOptions };
                        }
                        // Handle other fields
                        return { ...assignment, [field]: value };
                      }
                      return assignment;
                    }),
                  }
                : chapter
            ),
          }
        : section
    );
    setCourseObj((prev) => ({ ...prev, section: updatedSections }));
  };

  const addOptionToAssignment = (
    sectionIndex,
    chapterIndex,
    assignmentIndex
  ) => {
    const updatedSections = CourseObj.section.map((section, i) =>
      i === sectionIndex
        ? {
            ...section,
            chapter: section.chapter.map((chapter, j) =>
              j === chapterIndex
                ? {
                    ...chapter,
                    assessment: chapter.assessment.map((assignment, k) =>
                      k === assignmentIndex
                        ? {
                            ...assignment,
                            options: [...assignment.options, ""],
                          }
                        : assignment
                    ),
                  }
                : chapter
            ),
          }
        : section
    );
    setCourseObj((prev) => ({ ...prev, section: updatedSections }));
  };

  const removeOptionFromAssignment = (
    sectionIndex,
    chapterIndex,
    assignmentIndex,
    optionIndex
  ) => {
    const updatedSections = CourseObj.section.map(
      (section, i) =>
        i === sectionIndex
          ? {
              ...section,
              chapter: section.chapter.map((chapter, j) =>
                j === chapterIndex
                  ? {
                      ...chapter,
                      assessment: chapter.assessment.map((assignment, k) =>
                        k === assignmentIndex
                          ? {
                              ...assignment,
                              options: assignment.options.filter(
                                (_, idx) => idx !== optionIndex
                              ),
                            }
                          : assignment
                      ),
                    }
                  : chapter
              ),
            }
          : section /////////
    );
    setCourseObj((prev) => ({ ...prev, section: updatedSections }));
  };

  return (
    <>
      <button
        className="p-2 rounded-3"
        onClick={() => addAssignment(sectionIndex, chapterIndex)}
      >
        Add Question
      </button>
      {chapter.assessment.map((assignment, assignmentIndex) => (
        <div
          key={assignmentIndex}
          className="assignment-item d-flex flex-column gap-3 mt-3 p-3 border border-light rounded-3"
        >
          <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <Form.Label>Question {assignmentIndex + 1} ?</Form.Label>
              <button
                className="p-2 rounded-3 bg-danger"
                onClick={() =>
                  removeAssignment(sectionIndex, chapterIndex, assignmentIndex)
                }
              >
                <MdDeleteOutline color="white" fontSize={26} />
              </button>
            </div>
            <Form.Control
              type="text"
              placeholder="Question"
              className="w-100"
              value={assignment.question}
              onChange={(e) =>
                handleAssignmentChange(
                  sectionIndex,
                  chapterIndex,
                  assignmentIndex,
                  "question",
                  e.target.value
                )
              }
            />
          </Form.Group>
          <div className="mt-2 d-flex flex-column">
            <label className="text-black">Options:</label>
            <div className="d-flex flex-column gap-3 w-50">
              {assignment.options.map((option, optionIndex) => (
                <div className="d-flex gap-4 align-items-center ">
                  <Form.Control
                    key={optionIndex}
                    type="text"
                    placeholder={`Option ${optionIndex + 1}`}
                    value={option}
                    onChange={(e) =>
                      handleAssignmentChange(
                        sectionIndex,
                        chapterIndex,
                        assignmentIndex,
                        `options.${optionIndex}`,
                        e.target.value
                      )
                    }
                  />
                  <button
                    className="p-2 rounded-3 bg-danger"
                    onClick={() =>
                      removeOptionFromAssignment(
                        sectionIndex,
                        chapterIndex,
                        assignmentIndex,
                        optionIndex
                      )
                    }
                  >
                    <MdDeleteOutline color="white" fontSize={26} />
                  </button>
                </div>
              ))}
              <button
                className="p-2 rounded-3"
                onClick={() =>
                  addOptionToAssignment(
                    sectionIndex,
                    chapterIndex,
                    assignmentIndex
                  )
                }
              >
                Add Option
              </button>
            </div>
          </div>
          <div className="d-flex gap-5 justify-content-between w-100">
            <Form.Group className="mb-3 w-100">
              <Form.Label>Correct Answer</Form.Label>
              <Form.Control
                as="select"
                className="border border-dark"
                value={assignment.correctAnswer}
                onChange={(e) =>
                  handleAssignmentChange(
                    sectionIndex,
                    chapterIndex,
                    assignmentIndex,
                    "correctAnswer",
                    e.target.value
                  )
                }
              >
                <option disabled value="">
                  Select the correct answer
                </option>
                {assignment.options.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {/* <Form.Group
              className="mb-3 w-100"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Correct Answer</Form.Label>
              <select
                className="form-select form-select-lg border border-dark"
                aria-label="Default select example"
                name="jobcountry"
                value={assignment.hint}
                onChange={(e) =>
                  handleAssignmentChange(
                    sectionIndex,
                    chapterIndex,
                    assignmentIndex,
                    "hint",
                    e.target.value
                  )
                }
              >
                {assignment?.options?.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
                <option selected disabled>
                  {!!interviewState?.jobcountry
                    ? interviewState?.jobcountry
                    : "Select Country"}
                </option>
                {Countries?.map((item, index) => {
                  return (
                    <option key={index} value={item?.name}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
            </Form.Group> */}
            <Form.Group className="mb-3 w-100">
              <Form.Label>Hint</Form.Label>
              <Form.Control
                type="text"
                placeholder="Hint"
                value={assignment.hint}
                onChange={(e) =>
                  handleAssignmentChange(
                    sectionIndex,
                    chapterIndex,
                    assignmentIndex,
                    "hint",
                    e.target.value
                  )
                }
              />
            </Form.Group>
          </div>
        </div>
      ))}
    </>
  );
};

export default Assignment;
