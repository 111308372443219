export const addLiveSession = (data) => {
  return {
    type: "ADD_LIVE_SESSIONS",
    payload: {
      data: data,
    },
  };
};

export const multipleSession = (data) => {
  return {
    type: "ADD_MULTIPLE_LIVE_SESSIONS",
    payload: {
      data: data,
    },
  };
};

export const removeLiveSession = (data) => {
  return {
    type: "REMOVE_LIVE_SESSIONS",
    payload: {
      data: data,
    },
  };
};
export const removeMulitipleLiveSession = (data) => {
  return {
    type: "REMOVE_MULTIPLE_LIVE_SESSIONS",
    payload: {
      data: data,
    },
  };
};
export const saveSchedule = (data) => {
  return {
    type: "ADD_LIVE_SESSION_DATA",
    payload: {
      data: data,
    },
  };
};
export const saveMultipleSession = (data) => {
  return {
    type: "ADD_LIVE_SESSION_DATA_MULTIPLE",
    payload: {
      data: data,
    },
  };
};
export const singleSessionAction = (data) => {
  return {
    type: "SINGLE_SESSION_LIST",
    payload: {
      data: data,
    },
  };
};
export const saveScheduleSingle = (data) => {
  return {
    type: "SAVE_SINGLE_SESSION_LIST",
    payload: {
      data: data,
    },
  };
};
export const removeLiveSessionSingle = (data) => {
  return {
    type: "REMOVE_SINGLE_LIVE_SESSIONS",
    payload: {
      data: data,
    },
  };
};
