import React, { useEffect, useState } from 'react'
import CommonTable from '../Common/Table/CommonTable'
import Loader from '../Common/Loader/Loader'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom'
import { PostCollege, deleteCollege, getAllColleges, getAllCorporate, updateCollege, updateCollegeStatus, updateCorporate } from '../services/apis'
import { MdDelete, MdModeEdit } from 'react-icons/md'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form, Spinner } from 'react-bootstrap'
import DeleteModal from '../Common/Components/DeleteModal'
import { toast } from 'react-toastify'


function Corporate() {
    const [loadingCorporate, setloadingCorporate] = useState([])
    const [corporateData, setcorporateData] = useState([])

    const [ActivateData, setActivateData] = useState()
    const [isEdit, setisEdit] = useState(false)
    const [corporateForm, setcorporateForm] = useState({
        collegename: "",
        phoneNumber: "",
        email: "",
        password: ""
    })
    const navigate = useHistory()

    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            grow: 2
        },
        {
            name: 'Name',
            selector: row => row.firstname + row.lastname,
            sortable: true,
            grow: 2
        },
        {
            name: 'Phone Number',
            selector: row => row.phoneNumber,
            sortable: true
        },
        // {
        //     name: 'Status',
        //     selector: row => row.phoneNumber,
        //     sortable: true
        // },
        {
            name: 'Status',
            selector: row => <div>{row?.status ? <button className='btn-success w-100 p-2 px-3' onClick={() => {
                setActivateData(row)
                handleshowActivate()
            }}>Active</button> : <button className='btn-danger w-100 p-2 px-3' onClick={() => {
                setActivateData(row)
                handleshowActivate()
            }}>Deactive</button>}</div>,
            sortable: true
        },
        {
            name: 'Action',
            selector: row => <div className="d-flex gap-3">
                <button onClick={() => {
                    navigate.push(`/corporate/${row._id}/edit`)
                }} className="p-2 border rounded-2 border-dark cursor-pointer"><MdModeEdit fontSize={24} style={{ color: "blue" }} /> </button>

                {/* <span className="p-2 border rounded-2 border-dark cursor-pointer" onClick={() => {
                }} ><MdDelete onClick={() => {
                    handleshowDelete()
                    setDeleteData(row)
                    // handleCollegeDelete(row._id)
                }} fontSize={24} style={{ color: "red" }} /></span> */}
            </div>,
        },
    ]

    const activateCorporate = async (id) => {
        try {
            const payload = {
                ...ActivateData,
                status: true
            }
            const res = await updateCorporate(payload)
        } catch (error) {
            console.log(error)
        }
    }

    const deactivateCorporate = async (id) => {
        try {
            const payload = {
                ...ActivateData,
                status: false
            }
            const res = await updateCorporate(payload)

        } catch (error) {
            console.log(error)
        }
    }
    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === "collegename") {
            const regex = /\b\w/g;
            setcorporateForm({
                ...corporateForm, collegename: value.replace(regex, function (match) {
                    return match.toUpperCase();
                })
            })
        } else {
            setcorporateForm({ ...corporateForm, [name]: value })
        }
    }



    // modal
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setcorporateForm({
            collegename: "",
            phoneNumber: "",
            email: "",
            password: ""
        })
        setShow(false)
    };
    const handleShow = () => setShow(true);

    const fetchCorporate = async () => {
        try {
            setloadingCorporate(true)
            const res = await getAllCorporate()
            setcorporateData(res.data.data)
            setloadingCorporate(false)
            console.log(res)
        } catch (error) {
            setloadingCorporate(false)
            console.log(error)
        }
    }
    useEffect(() => {
        fetchCorporate()
    }, [])
    const [loadingSubmit, setloadingSubmit] = useState(false)

    const handleCorporateSubmit = async () => {
        setloadingSubmit(true)
        try {
            await PostCollege(collegeForm)
            fetchCorporate()
            setcorporateForm({
                collegename: "",
                phoneNumber: "",
                email: "",
                password: ""
            })
            handleClose()
            setloadingSubmit(false)
        } catch (error) {
            setloadingSubmit(false)
            console.log(error)
        }
    }
    const handleCorporateUpdate = async () => {
        setloadingSubmit(true)
        try {
            await updateCollege(collegeForm)
            fetchCorporate()
            setcorporateForm({
                collegename: "",
                phoneNumber: "",
                email: "",
                password: ""
            })
            handleClose()
            setloadingSubmit(false)
        } catch (error) {
            setloadingSubmit(false)
            console.log(error)
        }
    }
    const handleCorporateDelete = async (id) => {
        try {
            const newData = collegeData?.filter(item => item._id !== id)
            toast.success("Corporate Deleted")
            setcollegeData(newData)
            await deleteCollege(id)
        } catch (error) {
            console.log(error)
        }
    }



    // const  showDelete, handleDeleteDecision, field, HideDeleteModal
    const [showActivate, setshowActivate] = useState(false)
    const handleshowActivate = () => {
        setshowActivate(true)
    }
    const HideDeleteActivate = () => {
        setshowActivate(false)
    }
    const handleActivateDecision = (value) => {
        if (value) {
            if (ActivateData?.status) {
                deactivateCorporate(ActivateData._id)
                fetchCorporate()
                toast.success("Corporate Deactivated")
            } else {
                activateCorporate(ActivateData._id)
                toast.success("Corporate Activated")
                fetchCorporate()
            }
            // handleCollegeDelete(DeleteData?._id)
        } else {
            toast.error("No Corporate Activated")
        }
    }





    const csvData = corporateData?.map((row, index) => ({
        "S No.": index + 1,
        "Name": row.firstname + row.lastname,
        "Email": row.email,
        "Phone No.": row.phoneNumber,
        "Status": row.status ? "Active" : "Deactive"
    }));


    const [searchQuery, setsearchQuery] = useState('')

    const [FilteredData, setFilteredData] = useState([])
    useEffect(() => {
        handleSearchChange()
    }, [searchQuery, corporateData]);

    const handleSearchChange = async (e) => {
        if (searchQuery === '') {
            setFilteredData(corporateData);
        } else {
            const lowercasedQuery = searchQuery.toLowerCase();
            const filteredResults = corporateData.filter(item =>
                Object.values(item).some(
                    val => val && val.toString().toLowerCase().includes(lowercasedQuery)
                )
            );
            setFilteredData(filteredResults);
        }
    }


    return (
        <>
            <section >
                <div className="d-flex w-100 justify-content-end align-items-end">

                    <button onClick={() => {
                        navigate.push("/corporate/new")
                    }} className="p-3 rounded mb-4 text-white px-4 " style={{ background: "#fea302" }}>Create Corporate</button>
                </div>
                {
                    loadingCorporate ?
                        <div className="d-flex justify-content-center">
                            <Loader />
                        </div> :
                        <>
                            <CommonTable
                                data={FilteredData}
                                columns={columns}
                                csvData={csvData}
                                csvName={"Corporate-Data"}
                                setsearchQuery={setsearchQuery}
                            />
                        </>
                }

            </section >


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add College</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form>
                        <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
                            <Form.Label> Name of Corporate</Form.Label>
                            <Form.Control value={corporateForm?.collegename} type="text" name="collegename" onChange={handleChange} className='border border-dark' placeholder="For eg. Indira School of Business - MBA" />
                        </Form.Group>
                        <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
                            <Form.Label> Email</Form.Label>
                            <Form.Control value={corporateForm?.email} type="email" name="email" onChange={handleChange} className='border border-dark' placeholder="Email" />
                        </Form.Group>
                        <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
                            <Form.Label> Phone Number</Form.Label>
                            <Form.Control value={corporateForm?.phoneNumber} type="tel" name="phoneNumber" onChange={handleChange} className='border border-dark' placeholder="Phone" />
                        </Form.Group>
                        <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
                            <Form.Label> Password</Form.Label>
                            <Form.Control value={corporateForm?.password} type="password" name="password" onChange={handleChange} className='border border-dark' placeholder="Password" />
                        </Form.Group>
                    </Form>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    {
                        isEdit ?
                            <Button variant="primary" onClick={handleCorporateUpdate}>
                                Update Corporate
                                <span className='me-2'>
                                    {loadingSubmit && <Loader />}
                                </span>
                            </Button>
                            :
                            <Button variant="primary" onClick={handleCorporateSubmit}>

                                Add Corporate
                                <span className='me-2'>
                                    {loadingSubmit && <Loader />}
                                </span>
                            </Button>
                    }
                </Modal.Footer>
            </Modal>
            <DeleteModal handleDeleteDecision={handleActivateDecision} text={`Do you want to ${ActivateData?.status ? "Deactivate" : "Activate"} ${ActivateData?.email} ?`} showDelete={showActivate} HideDeleteModal={HideDeleteActivate} />


        </>
    )
}

export default Corporate