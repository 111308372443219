import { format } from "date-fns";
import { ColumnFilter } from "../FilteringTable/ColumnFilter";
export const COLUMNS = [
  {
    Header: "SNo",
    Footer: "SNo",
    accessor: "id",
    Filter: ColumnFilter,
    //disableFilters: true,
  },
  {
    Header: "name",
    Footer: "name",
    accessor: "name",
    Filter: ColumnFilter,
  },
  {
    Header: "Last Name",
    Footer: "Last Name",
    accessor: "last_name",
    Filter: ColumnFilter,
  },
  {
    Header: "Email Id",
    Footer: "Email Id",
    accessor: "email",
    Filter: ColumnFilter,
  },
  {
    Header: "Date of Birth",
    Footer: "Date of Birth",
    accessor: "date_of_birth",
    Cell: ({ value }) => {
      return format(new Date(value), "dd/mm/yyyy");
    },
    Filter: ColumnFilter,
  },

  {
    Header: "Phone",
    Footer: "Phone",
    accessor: "phone",
    Filter: ColumnFilter,
  },
  {
    Header: "Position",
    Footer: "Position",
    accessor: "payment",
    Filter: ColumnFilter,
  },
];

export const GROUPED_COLUMNS = [
  {
    Header: "Id",
    Footer: "Id",
    accessor: "id",
  },
  {
    Header: "Name",
    Footer: "Name",
    columns: [
      {
        Header: "name",
        Footer: "name",
        accessor: "name",
      },
      {
        Header: "Last Name",
        Footer: "Last Name",
        accessor: "last_name",
      },
    ],
  },
  {
    Header: "Info",
    Footer: "Info",
    columns: [
      {
        Header: "Date of  Birth",
        Footer: "Date of  Birth",
        accessor: "date_of_birth",
      },
      {
        Header: "Country",
        Footer: "Country",
        accessor: "country",
      },
      {
        Header: "Phone",
        Footer: "Phone",
        accessor: "phone",
      },
    ],
  },
];

export const TEAMS_TABLE = [
  {
    Header: "#",
    Footer: "#",
    accessor: "_id",
    Filter: ColumnFilter,
    //disableFilters: true,
  },
  {
    Header: "name",
    Footer: "name",
    accessor: "teamName",
    Filter: ColumnFilter,
    //disableFilters: true,
  },
  {
    Header: "createdAt",
    Footer: "createdAt",
    accessor: "createdAt",
    Filter: ColumnFilter,
    //disableFilters: true,
  },
];
export const COUPONTABLE = [
  {
    name: 'S no.',
    selector: (row, index) => index + 1,
    sortable: true,
    cellExport: (row) => "",
  },
  {
    name: 'Coupon Code',
    selector: (row, index) => row.couponCode,
    sortable: true,
    cellExport: (row) => row?.couponCode,
  },
  {
    name: 'Discount Type',
    selector: (row, index) => row.discountType,
    sortable: true,
    cellExport: (row) => row?.discountType,
  },
  {
    name: 'Discount Value',
    selector: (row, index) => row.discountValue,
    sortable: true,
    cellExport: (row) => row?.discountValue,
  },
  {
    name: 'Remaining Allowed',
    selector: (row, index) => row.numberofAllowed,
    sortable: true,
    cellExport: (row) => row?.numberofAllowed,
  }
];
