import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { pageBuilderActive } from "../../../../../store/actions/course";
import { PageBuild } from "./PageBuild";

function PageBuilderPreview() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
  }, [loading]);

  return (
    <>
      {loading && <div id="cover-spin"></div>}
      <div
        style={{ height: "150vh", width: "70vw", marginTop: "100px" }}
        className="d-flex justify-content-center "
      >
        <div>
          <button
            className="btn btn-danger"
            onClick={() => dispatch(pageBuilderActive(false))}
          >
            Exit
          </button>
        </div>
        <PageBuild />
      </div>
    </>
  );
}

export default PageBuilderPreview;
