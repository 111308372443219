import React, { useState,useEffect } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ShowActive } from "../../PluginsMenu/Nestable/utils";
import { Show } from "../../show";
import PageBuilderPreview from "./Chapter/PageBuilderPreview";
import Curriculam from "./Curriculam";
import LandingPage from "./LandingPage";
import Publish from "./Publish";
import AddCourse from "./CoursesCreation/AddCourse";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getSingleCourse } from "../../../../services/apis";

export const CourseMain = ({ next }) => {
  const [selected, setSelected] = useState(1);
  const { isPageBuilderActive } = useSelector((state) => state.courses);

  const [CourseObj, setCourseObj] = useState({
    name: "",
    bundleName: "",
    description: "",
    courseContents: "",
    prevPrice: "",
    category: "",
    newPrice: "",
    reviewStars: "",
    about: "",
    language: "",
    thumnailUrl: "",
    resources: "",
    isTool: false,
    section: [],
    isPublished:false
  });
  console.log(CourseObj);

  const list = [
    {
      id: 1,
      title: "Details",
    },
    {
      id: 2,
      title: "Curriculum",
    }

  ];
  const list2 = [
    {
      id: 1,
      title: "Details",
    }

  ];

  const addSection = () => {
    const newSection = {
      sectionID: Math.ceil(Math.random(1000000) * 10999900000000),
      sectionName: `Untitled ${count + 1}`,
      chapter: [],
      activeDate: "",
      availableAfterDays: 0,
      availableAfterWeeks: 0,
      dripBy: null,
      courseId: course.id,
    };

    setCourseObj((prevCourse) => ({
      ...prevCourse,
      section: [...prevCourse.section, newSection],
    }));

    setCount(count + 1);
  };
  const removeSection = (sectionID) => {
    setCourseObj((prevCourse) => ({
      ...prevCourse,
      section: prevCourse.section.filter(
        (section) => section.sectionID !== sectionID
      ),
    }));
  };
  const params = useParams()
const {id}=params
const fetchSingleCourse=async()=>{
  try {
const res= await getSingleCourse(id)
console.log(res)
setCourseObj(res.data.data)
  }catch(err){
    console.log(err)
  }
}
useEffect(() => {
  if(!!id){
    fetchSingleCourse() 
  }
}, [])


  const getRendered = (state) => {
    const Screen = {
      2: <AddCourse CourseObj={CourseObj} setCourseObj={setCourseObj} />,
      1: <Publish CourseObj={CourseObj} setCourseObj={setCourseObj} />,
      3: <LandingPage />,
    };
    if (!Screen[state]) return null;
    return Screen[state];
  };

  return (
    <>
      <Show condition={!isPageBuilderActive}>
        <div>
          <button className="btn btn-danger">
            <Link to="/course" className="white-100">
              <i class="fa-solid fa-arrow-left mx-1"></i> Back
            </Link>
          </button>
        </div>
        <div className="card-3 mt-4">
          <div className="tabbutton mt-5 pt-2" id="tabbutton">
            <ul className="d-flex justify-content-evenly">
              {(CourseObj?.isTool?list2 :  list)?.map((item) => (
                <ShowActive
                  title={item.title}
                  className="tabbutton"
                  active={selected === item.id}
                  setSelected={setSelected}
                  id={item.id}
                />
              ))}
            </ul>
            <div className="container">{getRendered(selected)}</div>
            <div className="d-flex justify-content-center">
              {selected > 1 && (
                <Button
                  className="mt-3 mx-2"
                  variant="danger"
                  onClick={() =>
                    setSelected(selected !== 1 ? selected - 1 : selected)
                  }
                >
                  &larr; Prev
                </Button>
              )}
              {selected === 1 && (
                <Button
                  className="mt-3"
                  variant="danger"
                  onClick={() =>
                    setSelected(selected !== 3 ? selected + 1 : selected)
                  }
                >
                  Next &rarr;
                </Button>
              )}
            </div>
          </div>
        </div>
      </Show>
      <Show condition={isPageBuilderActive}>
        <PageBuilderPreview />
      </Show>
    </>
  );
};
