const initialState = {
  categoryItems: [],
};

export const CategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_NEW_CATEGORY_ITEMS":
      return {
        ...state,
        categoryItems: action.payload.data,
      };
    case "DELETE_NEW_CATEGORY_ITEMS":
      return {
        ...state,
        categoryItems: state.categoryItems.filter((elment) => {}),
      };
    default:
      return state;
  }
};
