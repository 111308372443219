import { request } from "./clientApi";

export const URL = "https://pdf-storage-link.herokuapp.com";
// const baseURL = "http://localhost:3200"
const baseURL = "https://api.viosa.in"
const AddMedia = (callback, data) => {
  request(callback, URL + "/pdf/link", data, "POST");
};
const AddCourse = (callback, data) => {
  request(callback, `${baseURL}/admin/course/secure`, data, "POST");
};
const getCourseSection = (callback, id) => {
  request(callback, `${baseURL}/admin/course/byCourseId/${id}`);
};

const courseUpdate = (callback, data, id) => {
  request(
    callback,
    `${baseURL}/admin/course/?course_id=${id}`,
    data,
    "PATCH"
  );
};

const liveSessionApi = (callback, data) => {
  request(callback, `${baseURL}/admin/live-session`, data, "POST");
};

const publishCourse = (callback, data) => {
  request(
    callback,
    `${baseURL}/admin/course/publish/?course_id=${id}`,
    // is route se pehele update wala chalana hai course ko save krne ke liye  , pehele course save hoga fir publish hoga"
    data,
    "PATCH"
  );
};
const zoomMeeting = (callback, data) => {
  request(callback, `${baseURL}/zoom/schedule-meeting`, data, "POST");
};

const deleteCourse = (callback, data) => {
  request(
    callback,
    `${baseURL}/zoom/schedule-meeting`,
    data,
    "POST"
  );
};
export default {
  AddMedia,
  AddCourse,
  publishCourse,
  liveSessionApi,
  zoomMeeting,
  getCourseSection,
  courseUpdate,
};
