import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import profile from "../../../images/viosa.png";
import { FaUserFriends } from "react-icons/fa";
import { FaRupeeSign } from "react-icons/fa";
import { FaRegQuestionCircle } from "react-icons/fa";
import { SiCoursera } from "react-icons/si";

//Import
import { ThemeContext } from "../../../context/ThemeContext";
import RadialDount from "./Dashboard/RadialDount";

import {
  getCareer,
  getCollab,
  getCoursesList,
  getOrders,
  getSupport,
  getUsers,
} from "../../../services/PostsService";
import CommonTable from "../../../Common/Table/CommonTable";
import moment from "moment";
import { useCallback } from "react";
import debounce from 'lodash/debounce';


const Home = () => {
  const { changeBackground } = useContext(ThemeContext);
  const [orders, setOrders] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [users, setUsers] = useState([]);
  const [support, setSupport] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const history = useHistory();

  const fixedOrders = orders.slice(0, 5);

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await getOrders();
        setOrders(response.data.result);
        const { data } = await getCollab();
        const data2 = await getSupport();
        const data3 = await getCareer();
        setSupport(
          data?.result?.length +
          data2?.data?.result?.length +
          data3?.data?.result?.length
        );
      } catch (error) {
        if (error.response.data === "Invalid Token") {
          window.location.reload();
          history.push('/login')
          localStorage.clear()
        }
      }
    };
    fetch();

    const fetchCourses = async () => {
      const response = await getCoursesList();
      setCourseList(response.data.courses);
    };
    fetchCourses();

    changeBackground({ value: "light", label: "Light" });
  }, []);

  const [searchQuery, setsearchQuery] = useState('');
  const [searchUserQuery, setsearchUserQuery] = useState('');
  const [FilteredData, setFilteredData] = useState([]);
  const [FilteredUserData, setFilteredUserData] = useState([]);

  const fetchUsers = useCallback(async (searchUserQuery = '', currentPage = 1, perPage = 10) => {
    try {
      const query = {
        params: { perPage, currentPage, search: searchUserQuery },
      }
      const response = await getUsers(query);
      setUsers(response.data.result.users);
      setFilteredUserData(response.data.result.users);
      setTotalRows(response.data.result.totalDocuments);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const debouncedLoadData = useCallback(debounce(fetchUsers, 300), [fetchUsers]);

  useEffect(() => {
    debouncedLoadData(searchUserQuery, currentPage, perPage);
  }, [searchUserQuery, currentPage, perPage, debouncedLoadData]);

  useEffect(() => {
    if (searchQuery === '') {
      setFilteredData(fixedOrders);
    } else {
      const lowercasedQuery = searchQuery.toLowerCase();
      const filteredResults = fixedOrders.filter(item =>
        Object.values(item).some(
          val => val && val.toString().toLowerCase().includes(lowercasedQuery)
        )
      );
      setFilteredData(filteredResults);
    }
  }, [searchQuery, fixedOrders]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const totalEarning = orders.reduce((sum, item) => sum + item.amount, 0);
  const dayEarning = orders
    .filter((p) => Number(p.createdAt.split("T")[0].split("-")[2]) === new Date().getDate())
    .reduce((sum, item) => sum + item.amount, 0);

  const courseColumn = [
    {
      name: 'S No.',
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: 'Order Id',
      selector: (row) => row?.razorpay_order_id?.slice(6, 120) || row?.orderId?.slice(6, 120),
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row) => row?.userId?.name,
      sortable: true,
    },
    {
      name: 'Date',
      selector: (row) => moment(row?.createdAt).format('DD-MM-YYYY'),
      sortable: true,
    }
  ];

  const newUsersColumn = [
    {
      name: 'S No.',
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row?.email,
      sortable: true,
    },
    {
      name: 'Date',
      selector: (row) => moment(row?.createdAt).format('DD-MM-YYYY'),
      sortable: true,
    }
  ];

  const csvData = fixedOrders.map((row, index) => ({
    "S No.": index + 1,
    "Order Id": row?.razorpay_order_id?.slice(6, 120) || row?.orderId?.slice(6, 120),
    "Name": row.userId.name,
    "Date": moment(row?.createdAt).format('DD-MM-YYYY'),
  }));

  const csvUserData = users.map((row, index) => ({
    "S No.": index + 1,
    "Email": row?.email,
    "Name": row.name,
    "Date": moment(row?.createdAt).format('DD-MM-YYYY'),
  }));

  return (
    <>
      <div className="row d-flex justify-content-between mb-4">

        <div className="col-xl-3 col-sm-6">
          <div className="d-flex rounded p-4 justify-content-between  gradient-1 " onClick={() => history.push("/users")}>
            <div className="me-auto text-white">
              <h3 className="text-white">{totalRows}</h3>
              <span className="fs-18">Total Users</span>
            </div>
            <FaUserFriends color="white" fontSize={40} />
          </div>
        </div>

        <div className="col-xl-3 col-sm-6">
          <div className="d-flex rounded p-4 justify-content-between  gradient-2" onClick={() => history.push("/revenue")}>
            <div className="me-auto text-white">
              <h3 className="text-white">{totalEarning} Rs</h3>
              <span className="fs-18">Total Revenue</span>
            </div>
            <FaRupeeSign color="white" fontSize={40} />
          </div>
        </div>
        <div className="col-xl-3 col-sm-6">
          <div className="d-flex rounded p-4 justify-content-between  gradient-6" onClick={() => history.push("/query")}>
            <div className="me-auto text-white">
              <h3 className="text-white">{support} </h3>
              <span className="fs-18">Total Query</span>
            </div>
            <FaRegQuestionCircle color="white" fontSize={40} />
          </div>
        </div>
        <div className="col-xl-3 col-sm-6">
          <div className="d-flex rounded p-4 justify-content-between  gradient-7" onClick={() => history.push("/course")}>
            <div className="me-auto text-white">
              <h3 className="text-white">{courseList?.length} </h3>
              <span className="fs-18">Total Courses</span>
            </div>
            <SiCoursera color="white" fontSize={40} />
          </div>
        </div>
      </div>
      <div className="mb-4 d-flex gap-4">

        <div className="bg-white  w-100 p-3 rounded">
          <div className="card-header ">
            <h4 className="">Course Purchased</h4>
          </div>
          <div className="card-purch-list">
            <CommonTable csvData={csvData} csvName={"Course-Purchased-Data"} data={FilteredData} setsearchQuery={setsearchQuery} columns={courseColumn} />
          </div>
        </div>
        <div className="bg-white  w-100 p-3 rounded">
          <div className=" mb-3 card-header">
            <h4 className="">New Users</h4>
            <Link to={"/users"} className="btn-link">
              More
            </Link>
          </div>
          <div className="row">
            {/* {} */}
            <CommonTable csvData={csvUserData} tableType="user" totalRows={totalRows} handlePageChange={handlePageChange} handlePerRowsChange={handlePerRowsChange} csvName={"New-User-Data"} columns={newUsersColumn} setsearchQuery={setsearchUserQuery} data={FilteredUserData} />
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
