import { format } from "date-fns";
import { ColumnFilter } from "./ColumnFilter";
import moment from "moment";
export const COLUMNS = [
  {
    Header: "Id",
    Footer: "Id",
    accessor: "id",
    Filter: ColumnFilter,
    //disableFilters: true,
  },
  {
    Header: "First Name",
    Footer: "First Name",
    accessor: "first_name",
    Filter: ColumnFilter,
  },
  {
    Header: "Last Name",
    Footer: "Last Name",
    accessor: "last_name",
    Filter: ColumnFilter,
  },
  {
    Header: "Email Id",
    Footer: "Email Id",
    accessor: "email",
    Filter: ColumnFilter,
  },
  {
    Header: "Date of Paymemt",
    Footer: "Date of Paymemt",
    accessor: "date_of_birth",
    Cell: ({ value }) => {
      return format(new Date(value), "dd/mm/yyyy");
    },
    Filter: ColumnFilter,
  },

  {
    Header: "Phone",
    Footer: "Phone",
    accessor: "phone",
    Filter: ColumnFilter,
  },
  {
    Header: "Payment",
    Footer: "Payment",
    accessor: "payment",
    Filter: ColumnFilter,
  },
];


export const REVENUECOLUMNS = [
  // {
  //   Header: "Payment ID",
  //   Footer: "Payment ID",
  //   accessor: (d) => d.razorpay_payment_id || d.orderId,
  //   Filter: ColumnFilter,
  //   //disableFilters: true,
  // },
  {
    name: 'S No.',
    selector: (row, index) => index + 1,
    sortable: true,
    cellExport: (row, index) => "",
  },
  {
    name: 'Payment Id',
    selector: (row, index) => row.razorpay_payment_id || row.orderId,
    sortable: true,
    cellExport: (row) => row.razorpay_payment_id || row.orderId,
  },
  {
    name: 'Course Name',
    selector: (row, index) => row.courseName,
    sortable: true,
    cellExport: (row) => row.courseName,
  },
  {
    name: 'Name',
    selector: (row, index) => row?.userId?.name,
    sortable: true,
    cellExport: (row) => row?.userId?.name,
  },
  {
    name: 'Email Id',
    selector: (row, index) => row?.userId?.email,
    sortable: true,
    cellExport: (row) => row?.userId?.email,
  },
  {
    name: 'Purchase Date',
    selector: (row, index) => moment(row.createdAt).format("DD-MM-YYYY"),
    sortable: true,
    cellExport: (row) => moment(row.createdAt).format("DD-MM-YYYY"),

  },
  {
    name: 'Amount',
    selector: (row, index) => row.amount,
    sortable: true,
    cellExport: (row) => row?.amount,
  },
  {
    name: 'Status',
    selector: (row, index) => row.status,
    sortable: true,
    cellExport: (row) => row?.status,
  }
];

export const GROUPED_COLUMNS = [
  {
    Header: "Id",
    Footer: "Id",
    accessor: "id",
  },
  {
    Header: "Name",
    Footer: "Name",
    columns: [
      {
        Header: "First Name",
        Footer: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Last Name",
        Footer: "Last Name",
        accessor: "last_name",
      },
    ],
  },
  {
    Header: "Info",
    Footer: "Info",
    columns: [
      {
        Header: "Date of  Payment",
        Footer: "Date of  Payment",
        accessor: "date_of_birth",
      },
      {
        Header: "Country",
        Footer: "Country",
        accessor: "country",
      },
      {
        Header: "Phone",
        Footer: "Phone",
        accessor: "phone",
      },
    ],
  },
];

export const TEAMS_TABLE = [
  {
    Header: "#",
    Footer: "#",
    accessor: "_id",
    Filter: ColumnFilter,
    //disableFilters: true,
  },
  {
    Header: "name",
    Footer: "name",
    accessor: "teamName",
    Filter: ColumnFilter,
    //disableFilters: true,
  },
  {
    Header: "createdAt",
    Footer: "createdAt",
    accessor: "createdAt",
    Filter: ColumnFilter,
    //disableFilters: true,
  },
];
export const TEAMS_MEMBERS = [
  {
    Header: "name",
    Footer: "name",
    accessor: "name",
    Filter: ColumnFilter,
    //disableFilters: true,
  },
  {
    Header: "Team Name",
    Footer: "Team Name",
    accessor: "teamName",
    Filter: ColumnFilter,
    //disableFilters: true,
  },
  {
    Header: "course",
    Footer: "course",
    accessor: "course",
    Filter: ColumnFilter,
    //disableFilters: true,
  },
];

export const MEMBER_TABLE = [
  {
    Header: "name",
    Footer: "name",
    accessor: "name",
    Filter: ColumnFilter,
    //disableFilters: true,
  },
  {
    Header: "teamName",
    Footer: "teamName",
    accessor: "teamName",
    Filter: ColumnFilter,
  },
];

export const SUPPORT = [
  {
    name: 'S No.',
    selector: (row, index) => index + 1,
    sortable: true,
    cellExport: (row) => "",
  },
  {
    name: 'Name',
    selector: (row, index) => row.name,
    sortable: true,
    cellExport: (row) => row.name,
  },
  {
    name: 'Email',
    selector: (row, index) => row.email,
    sortable: true,
    cellExport: (row) => row.email,
  },
  {
    name: 'Date',
    selector: (row, index) => moment(row.createdAt).format('DD-MM-YYYY'),
    sortable: true,
    cellExport: (row) => moment(row.createdAt).format('DD-MM-YYYY'),
  },
  {
    name: 'Phone',
    selector: (row, index) => row.phone,
    sortable: true,
    cellExport: (row) => row.phone,
  },
  {
    name: 'Message',
    selector: (row, index) => row.message,
    sortable: true,
    cellExport: (row) => row.message,
  }
];
export const COLLAB = [
  {
    name: 'S no.',
    selector: (row, index) => index + 1,
    sortable: true,
    cellExport: (row) => "",
  },
  {
    name: 'School/College Name',
    selector: (row, index) => row.schoolOrCollege,
    sortable: true,
    cellExport: (row) => row.schoolOrCollege,
  },
  {
    name: 'Name',
    selector: (row, index) => row.name,
    sortable: true,
    cellExport: (row) => row.name,
  },
  {
    name: 'Email',
    selector: (row, index) => row.email,
    sortable: true,
    cellExport: (row) => row.email,
  },
  {
    name: 'Date',
    selector: (row, index) => moment(row.createdAt).format('DD-MM-YYYY'),
    sortable: true,
    cellExport: (row) => moment(row.createdAt).format('DD-MM-YYYY'),
  },
  {
    name: 'Phone',
    selector: (row, index) => row.phone,
    sortable: true,
    cellExport: (row) => row.phone,
  },
  {
    name: 'Message',
    selector: (row, index) => row.message,
    sortable: true,
    cellExport: (row) => row.message,
  }
];
export const CAREER = [
  {
    name: 'S No.',
    selector: (row, index) => index + 1,
    sortable: true,
    cellExport: (row) => "",
  },
  {
    name: 'Course Name',
    selector: (row, index) => row.course,
    sortable: true,
    cellExport: (row) => row?.course,
  },
  {
    name: 'Name',
    selector: (row, index) => row.name,
    sortable: true,
    cellExport: (row) => row?.name,
  },
  {
    name: 'Email',
    selector: (row, index) => row.email,
    sortable: true,
    cellExport: (row) => row?.email,
  },
  {
    name: 'Date',
    selector: (row, index) => moment(row.createdAt).format('DD-MM-YYYY'),
    sortable: true,
    cellExport: (row) => moment(row.createdAt).format('DD-MM-YYYY'),
  },
  {
    name: 'Phone',
    selector: (row, index) => row.phone,
    sortable: true,
    cellExport: (row) => row?.phone,

  },
  {
    name: 'Message',
    selector: (row, index) => row.message,
    sortable: true,
    grow: 3,
    cellExport: (row) => row?.message,
  },
];
