import React, { useEffect, useState } from 'react'

function VideoPlayer({ videourl }) {
    const [convertedURL, setconvertedURL] = useState(null)
    console.log(typeof videourl)
    useEffect(() => {
        if (typeof videourl === "string") {
            setconvertedURL(videourl)
        } else if (typeof videourl === "object") {
            setconvertedURL(URL?.createObjectURL(videourl))
        }
    }, [])
    // console.log(URL?.createObjectURL(videourl))
    // https://viosa-storage-2.s3.amazonaws.com/20240621T070916486ZDescribe%20a%20situation%20where%20you%20had%20to%20work%20in%20a%20team..mp4
    return (
        <>
            <video width="200" height="200" className='mt-3' controls>
                <source src={convertedURL} type="video/mp4" />
            </video>
        </>
    )
}

export default VideoPlayer