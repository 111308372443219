const initialState = {
  teams: [],
};

export const TeamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ALL_TEAMS":
      return {
        ...state,
        teams: action.payload.data,
      };

    default:
      return state;
  }
};
