import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ShowActive } from "../../../PluginsMenu/Nestable/utils";
import { Details } from "./Details";
import OngoingSession from "./OngoingSession";
import { MultipleSession } from "./Sessions/MultipleSession";
import { SingleSession } from "./Sessions/SingleSession";

export const Session = () => {
  const sessionName = window.location.pathname.split("/")[4];
  const cid = window.location.pathname.split("/")[3];
  const [screen, setScreen] = useState([]);
  const [selected, setSelected] = useState(sessionName);

  const list = [
    {
      id: "multiple-session",
      title: "Detail",
    },
    {
      id: "single-session",
      title: "Schedule",
    },
    {
      id: "3",
      title: "Confirm",
    },
  ];

  useEffect(() => {
    switch (selected) {
      case "multiple-session":
        setScreen(<Details selected={selected} />);
        break;
      case "single-session":
        setScreen(<MultipleSession />);
        break;
      case "3":
        setScreen(<OngoingSession />);
        break;
      default:
    }
  }, [selected]);

  return (
    <div className="container">
      <div className="card-3">
        <div>
          <button className="btn btn-danger white-100">
            <Link to={`/course/${cid}`} className="white-100">
              <i class="fa-solid fa-arrow-left mx-1"></i> Back
            </Link>
          </button>
          <h2 className="ml-4 d-flex justify-content-center session-details">
            Session Details
          </h2>
          <p className="ml-4 d-flex justify-content-center">
            Enter the details of your upcoming session here
          </p>
          <div className="tabbutton mt-5 pt-2" id="tabbutton">
            <ul className="d-flex">
              {list.map((item) => (
                <ShowActive
                  title={item.title}
                  icon={item.icon}
                  image={item.image}
                  className="tabbutton"
                  active={selected === item.id}
                  setSelected={setSelected}
                  id={item.id}
                />
              ))}
            </ul>
            <div className="container">{screen}</div>
          </div>
          {selected !== "3" && (
            <div className="d-flex justify-content-end flex-end">
              <button
                className="btn btn-secondary mt-3"
                onClick={() =>
                  selected === "multiple-session"
                    ? setSelected("single-session")
                    : setSelected("3")
                }
              >
                Next
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
