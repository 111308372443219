import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { getSingleCorporate, postCorporate, updateCorporate } from '../services/apis'
import Loader from '../Common/Loader/Loader'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom'
import { toast } from 'react-toastify'

function CreateCorporate() {
    const [corporateData, setcorporateData] = useState([])
    const navigate = useHistory()
    const params = useParams()
    const [loading, setloading] = useState(false)
    const [corporateForm, setcorporateForm] = useState({
        email: "",
        company: "",
        firstname: "",
        lastname: "",
        profileImg: "",
        phoneNumber: "",
        password: "",
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setcorporateForm({ ...corporateForm, [name]: value })
    }


    const uploadPhoto = async () => {
        try {
            if (corporateForm?.profileImg !== "" && typeof (corporateForm?.profileImg) === "object") {
                // debugger
                const config = {
                    onUploadProgress: (progress) => {
                        const percentange = Math.round(
                            (progress.loaded / progress.total) * 100
                        );
                        if (percentange === 100) {
                        }
                    },
                };
                const formData = new FormData();
                formData.append("file", corporateForm?.profileImg);
                const rootUrl = "https://uploader.viosa.in/api"
                const { data } = await axios.post(`${rootUrl}/v2/upload/other`, formData, config)
                // setcorporateForm((state) => ({ ...state, profileImg: data.link }))
                return data?.link
            }
            // alert("Videos Uploaded")
        } catch (error) {
            console.log(error)
            return null;

        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!corporateForm?.email || !corporateForm?.password) {
            toast.error("Please Fill details")
            return
        }
        setloading(true)
        let formdata;
        if (typeof (corporateForm?.profileImg) === "object") {
            const url = await uploadPhoto(); // First upload the photo
            formdata = { ...corporateForm, profileImg: url }
        } else {
            formdata = { ...corporateForm }
        }
        // Assuming photo upload sets the profileImg URL in the form data, now submit the form
        try {
            {
                !!params?.id ?
                    await updateCorporate(formdata)

                    :
                    await postCorporate(formdata)
            }
            setloading(false)
            navigate.push('/corporate')
        } catch (error) {
            console.log('Error submitting form:', error);
            // Handle error, e.g., show an error message
        }
    };

    // edit

    useEffect(() => {
        if (!!params?.id) {
            fetchSingleCorporate()
        }
    }, [])

    const fetchSingleCorporate = async () => {
        try {
            const res = await getSingleCorporate(params?.id)
            console.log(res)
            setcorporateForm(res.data.data)
        } catch (error) {
            console.log(error)
        }
    }
    console.log(corporateForm)

    return (
        <div className='d-flex justify-content-center flex-column align-items-center'>
            <Form className='col-8 '>
                <button className='mb-4 rounded common-btn p-2 px-3' onClick={() => navigate.push('/corporate')}>Back</button>
                <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
                    <Form.Label>Email <span className='text-danger'>*</span></Form.Label>
                    <Form.Control type="text" required name="email" value={corporateForm?.email} onChange={handleChange} className='border border-dark' placeholder="Email" />
                </Form.Group>
                <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control type="text" name="company" value={corporateForm?.company} onChange={handleChange} className='border border-dark' placeholder="Company" />
                </Form.Group>
                <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type="text" name="firstname" value={corporateForm?.firstname} onChange={handleChange} className='border border-dark' placeholder="First Name" />
                </Form.Group>
                <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text" name="lastname" value={corporateForm?.lastname} onChange={handleChange} className='border border-dark' placeholder="Last Name" />
                </Form.Group>
                <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
                    <Form.Label>Password <span className='text-danger'>*</span></Form.Label>
                    <Form.Control type="password" required name="password" value={corporateForm?.password} onChange={handleChange} className='border border-dark' placeholder="password" />
                </Form.Group>
                <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
                    <Form.Label>Phone Number <span className='text-danger'>*</span></Form.Label>
                    <Form.Control type="tel" required name="phoneNumber" value={corporateForm?.phoneNumber} onChange={handleChange} className='border border-dark' placeholder="Phone Number" />
                </Form.Group>
                <Form.Group className="mb-4 d-flex flex-column" controlId="exampleForm.ControlInput1">
                    <Form.Label>Profile Img</Form.Label>
                    <Form.Control type="file" className='border border-dark' onChange={(e) => setcorporateForm({ ...corporateForm, profileImg: e.target.files[0] })} placeholder="video" />

                    {/* <Form.Control type="text" name="email" value={corporateForm?.email} onChange={handleChange} className='border border-dark' placeholder="Full Name" /> */}
                </Form.Group>
                {
                    corporateForm?.profileImg &&
                    <div className='col-3'>
                        <img width={200} height={200} src={typeof (corporateForm?.profileImg) === "object" ? URL?.createObjectURL(corporateForm?.profileImg) : corporateForm?.profileImg} alt="" />
                    </div>
                }
                <div className="justify-content-end w-100 d-flex mt-3">
                    {
                        !!params?.id ?
                            <button className='common-btn p-2 rounded-3 px-4 justify-content-end' onClick={handleSubmit}>
                                Update
                                <span className='ms-2'>
                                    {
                                        loading && <Loader size="sm" color={"white"} />
                                    }
                                </span>
                            </button>
                            :
                            <button className='common-btn p-2 rounded-3 px-4 justify-content-end' onClick={handleSubmit}>
                                Submit
                                <span className='ms-2'>
                                    {
                                        loading && <Loader size="sm" color={"white"} />
                                    }
                                </span>
                            </button>
                    }
                </div>
            </Form>

        </div>
    )
}

export default CreateCorporate