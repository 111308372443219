import { object } from "yup";

const initialState = {
  livesession: [],
  singleSessionList: [],
  multipleSessionList: null,
};

export const LiveReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_LIVE_SESSIONS": {
      return {
        ...state,
        livesession: [...state.livesession, action.payload.data],
      };
    }
    case "REMOVE_LIVE_SESSIONS": {
      return {
        ...state,
        livesession: state.livesession.filter(
          (item) => item.id !== action.payload.data
        ),
      };
    }
    case "REMOVE_SINGLE_LIVE_SESSIONS": {
      return {
        ...state,
        singleSessionList: state.singleSessionList.filter(
          (item) => item.id !== action.payload.data
        ),
      };
    }
    case "REMOVE_MULTIPLE_LIVE_SESSIONS": {
      return {
        ...state,
        multipleSessionList: state.multipleSessionList.filter(
          (item) => item.id !== action.payload.data
        ),
      };
    }
    case "SINGLE_SESSION_LIST": {
      return {
        ...state,
        singleSessionList: [...state.singleSessionList, action.payload.data],
      };
    }

    case "SAVE_SINGLE_SESSION_LIST": {
      const updateListData = state.singleSessionList.map((item) => {
        if (item.id === action.payload.data.id) {
          item.name = action.payload.data.data.name;
          item.Startdate = action.payload.data.data.Startdate;
          item.start_time = action.payload.data.data.start_time;
          item.start_url = action.payload.data.start_url;
          item.join_url = action.payload.data.join_url;
          item.image = action.payload.data.data.image;
          item.endDate = action.payload.data.data.endDate;
          item.endTime = action.payload.data.data.endTime;
          item.timeZone = action.payload.data.data.timeZone;
        }
        return item;
      });
      return {
        ...state,
        singleSessionList: updateListData,
      };
    }
    case "ADD_MULTIPLE_LIVE_SESSIONS": {
      console.log("ADD_MULTIPLE_LIVE_SESSIONS");
      return {
        ...state,
        multipleSessionList: action.payload.data,
      };
    }
    case "ADD_LIVE_SESSION_DATA_MULTIPLE": {
      return {
        ...state,
        multipleSessionList: state.multipleSessionList.map((item) => {
          if (item.id == action.payload.data.id) {
            return Object.assign(item, { ...action.payload.data.data });
          }
          return item;
        }),
      };
    }
    default:
      return state;
  }
};
