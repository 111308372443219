import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormControl, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  CreateCategory,
  DeleteCategory,
  getAllCategory,
} from "../../../../../services/PostsService";

export const Category = () => {
  const [largeModal, setLargeModal] = useState(false);
  const [largeModal1, setLargeModal1] = useState(false);
  const [categories, setCategories] = useState([]);
  const { categoryItems } = useSelector((state) => state.categoryReducer);
  const [input, setInput] = useState({
    name: "",
    description: "",
  });

  console.log(categoryItems);
  const ChangeHandler = (e) => {
    const { name } = e.target;
    setInput({ ...input, [name]: e.target.value });
  };

  const createNewCategory = async () => {
    let newCategory = {
      name: input.name,
      id: Math.random(1000000).toString(29).substring(2, 10),
      description: input.description,
      date: new Date().toDateString(),
    };
    try {
      const createNewCategory = await CreateCategory(newCategory);
      toast.success("✔ New Category Added !", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      const category = await getAllCategory();
      setCategories(category.data.result);
      setLargeModal(false);
    } catch (e) {
      toast.error("❌ Something went wrong !", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const deleteCategory = async (id) => {
    if (window.confirm("Are you sure you want to delete this category ?")) {
      const data = {
        id: id,
      };
      const deleteCategory = await DeleteCategory(data);
      toast.success("✔ Category Removed!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      const filteredCategory = categories.filter(
        (category) => category._id !== id
      );
      setCategories(filteredCategory);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const category = await getAllCategory();
        setCategories(category.data.result);
      } catch {
        toast.error("❌ Something went wrong!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    };
    fetch();
  }, []);

  return (
    <div>
      <ToastContainer />
      <Link to="/course">
        <button className="btn btn-danger shadow">
          <i class="fa-solid fa-arrow-left mx-1"></i> Back
        </button>
      </Link>
      <div className="card-3 mt-4">
        <div class="overflow-x-auto">
          <Modal
            className="fade bd-example-modal-lg"
            show={largeModal}
            size="lg"
          >
            <Modal.Header>
              <Modal.Title>Add Category</Modal.Title>
              <Button
                variant=""
                className="btn-close"
                onClick={() => setLargeModal(false)}
              ></Button>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Category Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={input.name}
                        onChange={ChangeHandler}
                        name="name"
                        className="border-1"
                        placeholder="Enter Category name..."
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Decription</Form.Label>
                      <Form.Control
                        type="text"
                        value={input.description}
                        onChange={ChangeHandler}
                        name="description"
                        className="border-1"
                        placeholder="Enter category description..."
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <Button
                variant=""
                type="button"
                onClick={createNewCategory}
                className="btn btn-primary"
              >
                Add
              </Button>
              <Button
                variant="danger light"
                onClick={() => setLargeModal(false)}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            className="fade bd-example-modal-lg"
            show={largeModal1}
            size="lg"
          >
            <Modal.Header>
              <Modal.Title>Add SubCategory</Modal.Title>
              <Button
                variant=""
                className="btn-close"
                onClick={() => setLargeModal1(false)}
              ></Button>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Category Name</Form.Label>
                      <select className="form-control cursor-pointer">
                        {categories?.map((item) => (
                          <option value={item._id} key={item._id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>SubCategory Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={input.name}
                        onChange={ChangeHandler}
                        name="name"
                        className="border-1"
                        placeholder="Enter Category name..."
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Decription</Form.Label>
                      <Form.Control
                        type="text"
                        value={input.description}
                        onChange={ChangeHandler}
                        name="description"
                        className="border-1"
                        placeholder="Enter category description..."
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <Button
                variant=""
                type="button"
                onClick={createNewCategory}
                className="btn btn-primary"
              >
                Add
              </Button>
              <Button
                variant="danger light"
                onClick={() => setLargeModal(false)}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="d-flex justify-content-end flex-end">
            <button
              className="btn btn-success"
              onClick={() => setLargeModal(true)}
            >
              + New Category
            </button>
            <button
              className="btn btn-success mx-2"
              onClick={() => setLargeModal1(true)}
            >
              + New Subcategory
            </button>
          </div>
          <table class="table w-full">
            <thead>
              <tr>
                <th>Name</th>
                <th>Date</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {categories?.map((category) => (
                <tr key={category._id}>
                  <td>{category.name}</td>
                  <td>{new Date(category.createdAt).toDateString()}</td>
                  <td>{category.description}</td>
                  <td>
                    <div
                      className="cursor-pointer"
                      onClick={() => deleteCategory(category._id)}
                    >
                      Delete
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-between">
          {/*
        <div className="d-flex justify-content-end flex-end">
          <button className="next-button" onClick={CreateMeeting}>
            Confirm
          </button>
        </div> */}
        </div>
      </div>
    </div>
  );
};
