export const addCourse = (data) => {
  return {
    type: "ADD_COURSE",
    payload: {
      data: data,
    },
  };
};
export const allCourseList = (data) => {
  return {
    type: "ALL_COURSES",
    payload: {
      data: data,
    },
  };
};
export const addCourseData = (data) => {
  return {
    type: "COURSE_DATA",
    payload: {
      data: data,
    },
  };
};
export const CoursePublished = (data) => {
  return {
    type: "COURSE_PUBLISHED",
    payload: {
      data: data,
    },
  };
};
export const pageBuilderActive = (data) => {
  return {
    type: "COURSE_PAGE_BUILDER",
    payload: {
      data: data,
    },
  };
};
export const pageBuilderData = (data) => {
  return {
    type: "COURSE_PAGE_BUILDER_DATA",
    payload: {
      data: data,
    },
  };
};
