import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
import profile from "../../../images/viosa.png";
import avatar from "../../../images/avatar/8.jpg";
import { Dropdown } from "react-bootstrap";
import LogoutPage from "./Logout";
import { useSelector } from "react-redux";
import { getCoursesList, getOrders } from "../../../services/PostsService";
import { FaRegUser } from "react-icons/fa";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Header = ({ onNote }) => {
  var path = window?.location?.pathname?.split("/");
  var name = path[path.length - 1].split("-");
  const [courseList, setCourseList] = useState([]);

  var courseName;
  const { courses } = useSelector((state) => state.courses);
  const navigate = useHistory(); 
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const res= await getOrders()
      } catch (error) {
        if (error?.response?.status === 400) {
          console.log("Unauthorized access, redirecting to login...");
          navigate.push("/login"); // Redirect user to login page
          window.location.reload()
        } else {
          console.error("An error occurred:", error.message);
        }
      }
    };
    fetchCourses();
  }, []);

  if (name) {
    if (path[2]) {
      // courseName = "All Courses";

      let course = courseList.find((course) => course.course_id === path[2]);
      console.log(course, path[2]);
      courseName = course?.name;
    }
  }
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
      ? filterName.filter((f) => f !== "ui")
      : filterName.includes("uc")
        ? filterName.filter((f) => f !== "uc")
        : filterName.includes("basic")
          ? filterName.filter((f) => f !== "basic")
          : filterName.includes("jquery")
            ? filterName.filter((f) => f !== "jquery")
            : filterName.includes("table")
              ? filterName.filter((f) => f !== "table")
              : filterName.includes("page")
                ? filterName.filter((f) => f !== "page")
                : filterName.includes("email")
                  ? filterName.filter((f) => f !== "email")
                  : filterName.includes("ecom")
                    ? filterName.filter((f) => f !== "ecom")
                    : filterName.includes("chart")
                      ? filterName.filter((f) => f !== "chart")
                      : filterName.includes("editor")
                        ? filterName.filter((f) => f !== "editor")
                        : filterName;
  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {/* {courseName?.length > 0
                  ? courseName
                  : finalName.join(" ").length === 0
                    ? "Dashboard"
                    : finalName.join(" ") === "dashboard dark"
                      ? "Dashboard"
                      : finalName.join(" ")} */}
              </div>
            </div>
            <ul className="navbar-nav header-right main-notification">

              <Dropdown as="li" className="nav-item dropdown header-profile">
                <Dropdown.Toggle
                  variant=""
                  as="a"
                  className="nav-link i-false c-pointer"
                >
                  <img src={profile} width={20} alt="" />
                  <div className="header-info ms-3">
                    <span>Viosa</span>
                    <small>{localStorage.getItem('role') === "COLLEGE" ? "College" : "Super Admin"}</small>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu
                  align="right"
                  className="mt-3 dropdown-menu dropdown-menu-end border rounded-2 border-dark"
                >

                  <LogoutPage />
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
